var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      [
        _vm.report.handover && _vm.report.handover.handoverId
          ? _c(
              "v-timeline-item",
              {
                attrs: {
                  small: "",
                  icon: "mdi-handshake",
                  "fill-dot": ""
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common.nouns.handover")) + " ")]
                )
              ]
            )
          : _vm._e(),
        _vm.report.vehicleId
          ? _c(
              "v-timeline-item",
              {
                attrs: {
                  "hide-dot": ""
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "mx-n4 mt-n5"
                  },
                  [
                    _vm.loadingHandover
                      ? _c("v-skeleton-loader", {
                          attrs: {
                            type: "list-item"
                          }
                        })
                      : _vm.handover
                      ? _c("refs-handover", {
                          attrs: {
                            item: _vm.handover
                          },
                          on: {
                            openDetail: _vm.goToHandoverDetail
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      [
        _vm.report.handover &&
        _vm.report.handover.handoverId &&
        _vm.report.handover.foundInInspection
          ? _c(
              "v-timeline-item",
              {
                attrs: {
                  small: "",
                  icon: "mdi-binoculars",
                  "fill-dot": ""
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex"
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("common.nouns.inspection")) + " "
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm.report.vehicleId
          ? _c(
              "v-timeline-item",
              {
                attrs: {
                  "hide-dot": ""
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "mx-n4 mt-n5"
                  },
                  [
                    _vm.loadingInspection
                      ? _c("v-skeleton-loader", {
                          attrs: {
                            type: "list-item"
                          }
                        })
                      : _vm.inspection
                      ? _c("refs-inspection", {
                          attrs: {
                            item: _vm.inspection
                          },
                          on: {
                            openDetail: _vm.goToInspectionDetail
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }