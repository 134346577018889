





























































import PartnerDetailImage from "@/components/partner/PartnerDetailImage.vue";
import { domainRule } from "@/lib/rules/domainRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import {
  MrfiktivPartnerSettingsDtoGen,
  MrfiktivBookedServicesDtoGen,
  MrfiktivEmailSettingsDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { CreatePartnerDto } from "@/store/models/createPartnerDto";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    PartnerDetailImage
  }
})
export default class PartnerCreateOrUpdateLicense extends Vue {
  @Prop()
  settings!: MrfiktivPartnerSettingsDtoGen;

  @Prop()
  disabled!: boolean;

  get rules() {
    return [requiredRule()];
  }

  get domainRules() {
    const rules = [];
    rules.push(requiredRule());
    rules.push(domainRule());
    return rules;
  }

  get emailSettings(): MrfiktivEmailSettingsDtoGen {
    if (!this.settings.emailSettings) {
      return CreatePartnerDto.EMPTY_EMAIL_SETTINGS;
    }

    return this.settings.emailSettings;
  }

  get bookedServices(): MrfiktivBookedServicesDtoGen {
    if (!this.settings.bookedServices) {
      return CreatePartnerDto.EMPTY_BOOKED_SERVICES;
    }
    return this.settings.bookedServices;
  }
}
