var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("layout-report-with-banner", {
    attrs: {
      showFooter: false,
      isProgressDone: true
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function fn() {
          return [
            _c("notifications", {
              staticClass: "ma-1"
            }),
            _c("table-wrapper", {
              attrs: {
                headers: _vm.headers,
                allItems: _vm.items,
                loading: _vm.loading,
                sortBy: "start",
                sortDesc: true
              },
              on: {
                "click:row": _vm.openBooking
              },
              scopedSlots: _vm._u([
                {
                  key: "item.timestamp.created",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("simpleDoubleDigitDate")(
                              item.timestamp.created
                            )
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.service",
                  fn: function fn(_ref2) {
                    var _vm$serviceName

                    var item = _ref2.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm$serviceName = _vm.serviceName(
                              item.serviceId
                            )) === null || _vm$serviceName === void 0
                              ? void 0
                              : _vm$serviceName.name
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.start",
                  fn: function fn(_ref3) {
                    var item = _ref3.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("simpleDoubleDigitDate")(
                              new Date(item.start)
                            )
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.startTime",
                  fn: function fn(_ref4) {
                    var item = _ref4.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatHoursAndMinutes")(
                              new Date(item.start)
                            )
                          ) +
                          " Uhr "
                      )
                    ]
                  }
                },
                {
                  key: "item.end",
                  fn: function fn(_ref5) {
                    var item = _ref5.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("simpleDoubleDigitDate")(new Date(item.end))
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.endTime",
                  fn: function fn(_ref6) {
                    var item = _ref6.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatHoursAndMinutes")(new Date(item.end))
                          ) +
                          " Uhr "
                      )
                    ]
                  }
                },
                {
                  key: "item.duration",
                  fn: function fn(_ref7) {
                    var item = _ref7.item
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("msToTime")(item.duration)) + " "
                      )
                    ]
                  }
                }
              ])
            }),
            _vm.selectedBookingId
              ? _c("my-booking-detail-side-card", {
                  attrs: {
                    booking: _vm.selectedBooking
                  },
                  on: {
                    close: function close($event) {
                      _vm.selectedBookingId = null
                    },
                    reload: _vm.load
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }