



















































import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { RefTypeMap } from "@/store/modules/refs.store";
import { MrfiktivReferenceGen, MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { IRefSuggestion } from "@/store/modules/fleet-aggregation.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import VirtualList from "vue-virtual-scroll-list";
import draggable from "vuedraggable";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import RefsList from "../utility/RefsList.vue";
import AEicon from "./AEicon.vue";
import RefsSelectionSuggestion from "./RefsSelectionSuggestion.vue";
import Tooltip from "./tooltip.vue";
import RefsSelectionByType from "./RefsSelectionByType.vue";

@Component({
  components: {
    RefsList,
    draggable,
    VirtualList,
    Tooltip,
    RefsSelectionSuggestion,
    AEicon,
    LatestEntriesCardEmpty,
    RefsSelectionByType
  },
  filters: {}
})
export default class RefsSelection extends Vue {
  @Prop()
  private value!: MrfiktivReferenceGen[];

  @Prop({ default: () => [] })
  suggested!: IRefSuggestion[];

  @Prop()
  partnerId!: string;

  @Prop({
    default: () => [
      BackendResourceEnum.VEHICLE,
      BackendResourceEnum.EVENT,
      BackendResourceEnum.REPORT,
      BackendResourceEnum.TICKET
    ]
  })
  categories!: BackendResourceEnum[];

  @Prop({ default: false })
  loading!: boolean;

  /**
   * Are new references added to the top or the bottom of the reference list
   */
  @Prop()
  addToTop?: boolean;

  @Prop({ default: false })
  hideSuggestions!: boolean;

  @Prop({ default: true })
  outlined!: boolean;

  readonly SUGGESTION = "suggestion";

  readonly RefTypeMap = RefTypeMap;

  readonly BackendResourceEnum = BackendResourceEnum;

  selectedCategory =
    this.suggested.length + this.value.filter(d => d.refType === BackendResourceEnum.VEHICLE).length &&
    !this.hideSuggestions
      ? this.SUGGESTION
      : this.categories[0];

  get selected(): MrfiktivReferenceGen[] {
    return this.value;
  }
  set selected(v: MrfiktivReferenceGen[]) {
    this.$emit("input", v);
  }

  getRefTypeName(refType: BackendResourceEnum) {
    if (refType === BackendResourceEnum.EVENT) {
      return this.$t("common.nouns.appointments");
    }
    return this.$t(`BackendResourceEnum.${refType}`);
  }

  async addToSelected(item: IRefSuggestion) {
    const selection: MrfiktivReferenceGen[] = [];

    if (item.refType === BackendResourceEnum.EVENT) {
      const event = item.ref as IEventUIDto;

      // also add the vehicle if you add an event
      if (event.vehicleId && !this.selected.find(selected => selected.refId === event.vehicleId)) {
        selection.push({
          refId: event.vehicleId,
          refType: BackendResourceEnum.VEHICLE
        });
      }
    }

    // also add the vehicle if you add a report
    if (item.refType === BackendResourceEnum.REPORT) {
      const report = item.ref as MrfiktivReportViewModelGen;

      if (report.vehicleId && !this.selected.find(selected => selected.refId === report.vehicleId)) {
        selection.push({
          refId: report.vehicleId,
          refType: BackendResourceEnum.VEHICLE
        });
      }
    }

    if (!item.ref.id) {
      return;
    }

    selection.push({ refId: item.ref.id, refType: item.refType });

    if (this.addToTop) {
      this.selected.unshift(...selection);
    } else {
      this.selected.push(...selection);
    }

    this.$forceUpdate();
    this.$emit("added");
  }
}
