







































import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { detailedDate } from "@/lib/utility/date-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { TimeStampEntity } from "@/models/timestampEntity";
import { MessageModule } from "@/store/modules/message.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import TemplateCard from "../template/TemplateCard.vue";
import TemplateDialog from "../template/TemplateDialog.vue";
import TemplateEditor from "../template/TemplateEditor.vue";
import TimelineCard from "../utility/TimelineItem.vue";

@Component({
  components: { TemplateEditor, TimelineCard, TemplateCard, TemplateDialog }
})
export default class PartnerMessageDetail extends PermissionMixin {
  get message() {
    return MessageModule.message;
  }

  get fromMail() {
    const fromMail = PartnerModule.partner.companyName;
    let fromUser = "";
    if (this.message?.userId) {
      fromUser = this.$t("components.PartnerMessageDetail.toAlt", { mail: this.message?.userId }).toString();
    }
    return fromUser + fromMail;
  }

  get date() {
    if ((this.message?.timestamp as TimeStampEntity).created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";

      return detailedDate((this.message?.timestamp as TimeStampEntity).created, locale);
    }
    return "";
  }

  get partner() {
    return PartnerModule.partner;
  }

  get from() {
    return this.partner;
  }

  get to(): string[] {
    if (!this.message?.to) {
      return [];
    }
    return [this.message?.to];
  }

  get context(): ITemplateContext {
    return { partner: this.partner };
  }
}
