

























































import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import RegistrationUpdateDialog from "@/components/utility/RegistrationUpdateDialog.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { RegistrationResultsEntity } from "@/models/registrationResultsEntity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import aiService from "@/services/mrfiktiv/services/aiService";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    RegistrationUpdateDialog,
    TimelineCard,
    Card
  }
})
export default class PartnerRegistrationTimeLineElement extends DarkModeHighlightMixin {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  @Prop()
  report!: MrfiktivReportViewModelGen;

  @Prop({ default: false })
  loading!: boolean;

  loadRegistrationResult = false;

  update(registration: RegistrationResultsEntity) {
    this.$emit("update", registration);
  }

  get notEmptyData() {
    if (this.registrationData) {
      return this.registrationData.filter(item => !!item.show);
    }
    return [];
  }

  get headers() {
    return [
      { text: "Schlüssel", value: "key", width: 100, align: "start" },
      { text: "Wert", value: "value" }
    ];
  }

  get registrationImage() {
    if (this.report?.images?.registrations.length > 0) {
      return this.report?.images.registrations[0];
    }

    return this.report.images.overviews.find(i => i.type === ReportImageType.registration);
  }

  get registrationImageLink(): string {
    if (this.report?.images?.registrations.length > 0) {
      return this.report?.images.registrations[0].url;
    }

    const registration = this.registrationImage;
    if (registration) {
      return registration.url;
    }

    return "";
  }

  get registration() {
    return this.report.registrationResults;
  }

  get numberplate() {
    return this.report.numberplate;
  }

  get registrationData() {
    if (!this.registration || !this.numberplate) {
      return [];
    }
    return [
      {
        show: !!this.numberplate,
        key: this.$t("components.partner.PartnerRegistrationCard.numberplate"),
        value: this.numberplate
      },
      {
        show: !!this.registration?.name,
        key: this.$t("components.partner.PartnerRegistrationCard.name"),
        value: this.registration?.name
      },
      {
        show: !!this.registration?.firstname,
        key: this.$t("components.partner.PartnerRegistrationCard.firstname"),
        value: this.registration?.firstname
      },
      {
        show: !!this.registration?.identificationnumber,
        key: this.$t("components.partner.PartnerRegistrationCard.identificationnumber"),
        value: this.registration?.identificationnumber
      },
      {
        show: !!this.registration?.manufacturerNameCode,
        key: this.$t("components.partner.PartnerRegistrationCard.manufacturerNameCode"),
        value: this.registration?.manufacturerNameCode
      },
      {
        show: !!this.registration?.manufacturerTypeCode,
        key: this.$t("components.partner.PartnerRegistrationCard.manufacturerTypeCode"),
        value: this.registration?.manufacturerTypeCode
      },
      {
        show: !!this.registration?.firstregistrationDay,
        key: this.$t("components.partner.PartnerRegistrationCard.firstregistration"),
        value:
          this.registration?.firstregistrationDay +
          "." +
          this.registration?.firstregistrationMonth +
          "." +
          this.registration?.firstregistrationYear
      },
      {
        show: !!this.registration?.huMonth,
        key: this.$t("components.partner.PartnerRegistrationCard.hu"),
        value: this.registration?.huMonth + "." + this.registration?.huYear
      },
      {
        show: !!this.registration?.driveTyp,
        key: this.$t("components.partner.PartnerRegistrationCard.driveTyp"),
        value: this.registration?.driveTyp
      },
      {
        show: !!this.registration?.vehicleClass,
        key: this.$t("components.partner.PartnerRegistrationCard.vehicleClass"),
        value: this.registration?.vehicleClass
      },
      {
        show: !!this.registration?.manufacturerName,
        key: this.$t("components.partner.PartnerRegistrationCard.manufacturerName"),
        value: this.registration?.manufacturerName
      },
      {
        show: !!this.registration?.manufacturerType,
        key: this.$t("components.partner.PartnerRegistrationCard.manufacturerType"),
        value: this.registration?.manufacturerType
      },
      {
        show: !!this.registration?.manufacturerDescription,
        key: this.$t("components.partner.PartnerRegistrationCard.manufacturerDescription"),
        value: this.registration?.manufacturerDescription
      }
    ];
  }

  async readRegistrationImage() {
    if (!this.registrationImage || !this.registrationImage.id) {
      return;
    }

    this.$log.debug("start", this.registrationImage);

    try {
      this.loadRegistrationResult = true;
      const result = await aiService.getRegistrationResult(this.report.partnerId, this.registrationImage.id);
      this.$log.info(result);

      this.registration.identificationnumber = result.vehicle_identification_number;

      if (result.error) {
        this.$toast.warning(result.error);
      }

      (this.$refs.dialog as RegistrationUpdateDialog).resetDialog();
    } finally {
      this.loadRegistrationResult = false;
    }
  }

  get canReadRegistrationImage() {
    return this.registrationImage && this.registrationImage.id;
  }
}
