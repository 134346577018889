var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            attrs: {
              type:
                "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
            }
          })
        : _vm.report._id
        ? _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c("m-header", {
                attrs: {
                  title: _vm.title,
                  subtitle: _vm.date,
                  chips: _vm.chips,
                  breadCrumbs: _vm.breadCrumbs,
                  alerts: _vm.alerts,
                  actions: _vm.actions
                },
                on: {
                  actionClicked: _vm.processAction,
                  alertClicked: _vm.processAction
                }
              }),
              _c(
                "v-row",
                {
                  staticClass: "d-none d-print-inline mx-4 mt-n4"
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("common.nouns.report")) +
                        " " +
                        _vm._s(_vm.report.id)
                    )
                  ])
                ]
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4"
                },
                [
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.UPDATE,
                        a: _vm.ResourceEnum.REPORT
                      }
                    },
                    [
                      _vm.isUpdateProgressStatusEnabled
                        ? _c("partner-report-detail-change-progress")
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.READ,
                        a: _vm.ResourceEnum.USER
                      }
                    },
                    [
                      _c("v-select", {
                        key: "v-select-assignees" + _vm.updateLoading,
                        attrs: {
                          "item-value": "id",
                          "item-text": "firstName",
                          label: _vm.$t("project.ticket.assignees"),
                          items: _vm.users,
                          multiple: "",
                          flat: "",
                          clearable: "",
                          solo: "",
                          dense: "",
                          loading: _vm.updateLoading,
                          disabled: !_vm.canUpdateReport || _vm.updateLoading
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function fn(_ref) {
                              var _vm$report$assignees, _vm$report$assignees2

                              var item = _ref.item,
                                index = _ref.index
                              return [
                                index === 0
                                  ? _c(
                                      "v-chip",
                                      _vm._b(
                                        {
                                          attrs: {
                                            outlined: ""
                                          }
                                        },
                                        "v-chip",
                                        item.attrs,
                                        false
                                      ),
                                      [
                                        item.firstName && item.lastName
                                          ? _c(
                                              "v-avatar",
                                              {
                                                staticClass: "white--text",
                                                attrs: {
                                                  color: "primary",
                                                  small: "",
                                                  left: ""
                                                }
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.firstName[0]
                                                      ) +
                                                      _vm._s(item.lastName[0])
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-truncate",
                                            style:
                                              ((_vm$report$assignees =
                                                _vm.report.assignees) ===
                                                null ||
                                              _vm$report$assignees === void 0
                                                ? void 0
                                                : _vm$report$assignees.length) >
                                              1
                                                ? "max-width: 75px;"
                                                : "max-widht: 100px"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.firstName) +
                                                " " +
                                                _vm._s(item.lastName) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                index === 1
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "grey--text "
                                      },
                                      [
                                        _vm._v(
                                          " (+" +
                                            _vm._s(
                                              ((_vm$report$assignees2 =
                                                _vm.report.assignees) ===
                                                null ||
                                              _vm$report$assignees2 === void 0
                                                ? void 0
                                                : _vm$report$assignees2.length) -
                                                1
                                            ) +
                                            ") "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function fn(_ref2) {
                              var item = _ref2.item
                              return [
                                _c(
                                  "v-list-item-avatar",
                                  [
                                    item.firstName && item.lastName
                                      ? _c(
                                          "v-avatar",
                                          {
                                            staticClass: "white--text",
                                            attrs: {
                                              color: "primary",
                                              small: "",
                                              size: "35px",
                                              right: true
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.firstName[0]) +
                                                _vm._s(item.lastName[0]) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.firstName) +
                                          " " +
                                          _vm._s(item.lastName) +
                                          " "
                                      )
                                    ]),
                                    _c("v-list-item-subtitle", [
                                      _vm._v(" " + _vm._s(item.userName) + " ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.assignees,
                          callback: function callback($$v) {
                            _vm.assignees = $$v
                          },
                          expression: "assignees"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c("debug", {
                attrs: {
                  debug: _vm.report
                }
              }),
              _c("debug", {
                attrs: {
                  debug: _vm.activityLog
                }
              }),
              _vm.isUpdateProgressStatusEnabled
                ? _c("partner-report-detail-delete-dialog", {
                    ref: "deleteDialog",
                    attrs: {
                      hideButton: true
                    },
                    on: {
                      setReport: _vm.setReport
                    }
                  })
                : _vm._e(),
              _c("partner-report-update-report-dialog", {
                ref: "updateDialog",
                attrs: {
                  hideButton: true,
                  config: [
                    _vm.ReportUpdateTabEnum.report,
                    _vm.ReportUpdateTabEnum.accident,
                    _vm.ReportUpdateTabEnum.witness,
                    _vm.ReportUpdateTabEnum.police,
                    _vm.ReportUpdateTabEnum.contact,
                    _vm.ReportUpdateTabEnum.leasing,
                    _vm.ReportUpdateTabEnum.preExisting,
                    _vm.ReportUpdateTabEnum.body,
                    _vm.ReportUpdateTabEnum.images,
                    _vm.ReportUpdateTabEnum.insurance
                  ]
                }
              }),
              _c("send-to-extern-option", {
                ref: "sendToExternDialog",
                attrs: {
                  hideButton: true
                }
              }),
              _c("report-document-sign-request-dialog", {
                ref: "signDialog",
                attrs: {
                  report: _vm.report
                }
              }),
              _c("report-activity-share-dialog", {
                ref: "shareDialog"
              }),
              _c("report-activity-upload-dialog", {
                ref: "uploadDialog"
              }),
              _vm.isTemplateActive && _vm.canCreateMessage
                ? _c("template-dialog", {
                    ref: "templateDialog",
                    attrs: {
                      hideButton: true,
                      partnerId: _vm.partner._id,
                      context: _vm.templateDialogMethods.context,
                      to: _vm.templateDialogMethods.to,
                      from: _vm.templateDialogMethods.from
                    },
                    on: {
                      sent: _vm.sent
                    }
                  })
                : _vm._e(),
              _c("ticket-create-dialog", {
                key: "ticket-create-dialog" + JSON.stringify(_vm.ticketRefs),
                ref: "ticketDialog",
                attrs: {
                  hideButton: true,
                  isIconButton: true,
                  isDialogActive: _vm.isTicketCreateDialogActive,
                  partnerIdProp: _vm.report.partnerId,
                  onStartDialog: _vm.setRefs,
                  refs: _vm.ticketRefs
                },
                on: {
                  "update:isDialogActive": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.isTicketCreateDialogActive = $event
                  },
                  "update:is-dialog-active": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.isTicketCreateDialogActive = $event
                  },
                  created: _vm.onTicketCreated
                }
              }),
              _c("cost-create-dialog", {
                key: "cost-create-dialog" + JSON.stringify(_vm.ticketRefs),
                ref: "costDialog",
                attrs: {
                  isDialogActive: _vm.isCostCreateDialogActive,
                  hideButton: true,
                  partnerId: _vm.report.partnerId,
                  refs: _vm.ticketRefs
                },
                on: {
                  "update:isDialogActive": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.isCostCreateDialogActive = $event
                  },
                  "update:is-dialog-active": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.isCostCreateDialogActive = $event
                  },
                  createdActivity: _vm.onCreatedActivity
                }
              }),
              _c(
                "v-card",
                {
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c(
                    "div",
                    {
                      class: _vm.displayToDetailView
                        ? "scrollable virtual-scroller"
                        : "",
                      style: _vm.displayToDetailView
                        ? "height: ".concat(_vm.height)
                        : ""
                    },
                    [
                      _vm.loading
                        ? _c("v-skeleton-loader")
                        : _vm.isTimelineEnabled
                        ? _c("partner-report-detail-info-time-line-card", {
                            on: {
                              showSignDialog: _vm.showSignDialog,
                              showEmailDialog: _vm.showEmailDialog,
                              showShareDialog: _vm.showShareDialog,
                              showUploadDialog: _vm.showUploadDialog,
                              showTicketDialog: _vm.showTicketDialog
                            }
                          })
                        : _c("partner-report-detail-info-card"),
                      !_vm.isTimelineEnabled &&
                      _vm.activityLog &&
                      _vm.activityLog.length >= 1
                        ? _c(
                            "v-card",
                            {
                              attrs: {
                                flat: ""
                              }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12"
                                      }
                                    },
                                    [
                                      _c("table-wrapper", {
                                        attrs: {
                                          title: _vm.$t(
                                            "partner.ActivityLogTable.title"
                                          ),
                                          headers: _vm.headers,
                                          allItems: _vm.activityLog
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.timestamp.created",
                                              fn: function fn(_ref3) {
                                                var item = _ref3.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "simpleDoubleDigitDate"
                                                        )(
                                                          item.timestamp.created
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.actionType",
                                              fn: function fn(_ref4) {
                                                var item = _ref4.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "partner.ActivityLogTable.actionType." +
                                                            item.actionType
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.activity",
                                              fn: function fn(_ref5) {
                                                var item = _ref5.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "partner.ActivityLogTable." +
                                                            item.activity
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.comment",
                                              fn: function fn(_ref6) {
                                                var item = _ref6.item
                                                return [
                                                  item.target
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          item.target,
                                                          function(t, i) {
                                                            return _c(
                                                              "span",
                                                              {
                                                                key: i
                                                              },
                                                              [
                                                                t.refType ===
                                                                "sign"
                                                                  ? _c(
                                                                      "router-link",
                                                                      {
                                                                        attrs: {
                                                                          to: {
                                                                            name:
                                                                              "SignRequestDetailView",
                                                                            params: {
                                                                              partnerId:
                                                                                item.partnerId,
                                                                              signRequestId:
                                                                                t.refId
                                                                            }
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              t.refId
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          290100823
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "v-card",
            {
              staticClass: "fill-height",
              attrs: {
                fluid: "",
                flat: ""
              }
            },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "justify-center": "",
                    "align-center": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        shrink: ""
                      }
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
                          "max-height": "350px",
                          contain: ""
                        }
                      }),
                      _c(
                        "v-row",
                        {
                          attrs: {
                            align: "center pt-8"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center"
                            },
                            [
                              _c("partner-report-initialize-dialog", {
                                attrs: {
                                  icon: false
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }