



















































































































































































































































































































import PartnerDetailImage from "@/components/partner/PartnerDetailImage.vue";
import PublicImageUploadDialog from "@/components/public-image/PublicImageUploadDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { ReportSettingsEntity } from "@/models/reportSettings";
import { PublicImageImageViewmodelGen } from "@/services/image/v1/data-contracts";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { CreatePartnerDto } from "@/store/models/createPartnerDto";
import { Component, Prop, Vue } from "vue-property-decorator";
import SelectAndOrderListDialog from "./SelectAndOrderListDialog.vue";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { PublicImageFolderEnum } from "@/store/enum/public-image/publicImageFolderEnum";
import draggable from "vuedraggable";

@Component({
  components: {
    PartnerDetailImage,
    PublicImageUploadDialog,
    SelectAndOrderListDialog,
    draggable
  }
})
export default class PartnerCreateOrUpdateReportSettings extends Vue {
  @Prop()
  reportSettings!: ReportSettingsEntity;

  @Prop()
  disabled!: boolean;

  screenTypes = Object.keys(ReportScreenEnum).map(k => ReportScreenEnum[k as any]);

  get rules() {
    return [requiredRule()];
  }

  get folderName() {
    return this.ghgContext ? PublicImageFolderEnum.THG : PublicImageFolderEnum.PARTNER;
  }

  updateScreenOrder(screenOrder: ReportScreenEnum[]) {
    this.reportSettings.screenOrder = screenOrder;
  }

  handleLogo(image: PublicImageImageViewmodelGen) {
    if (image && image.cdnUrl) {
      this.reportSettings.logoUrl = image.cdnUrl;
    }
  }

  handleHeader(image: PublicImageImageViewmodelGen) {
    if (image && image.cdnUrl) {
      this.reportSettings.headerImageUrl = image.cdnUrl;
    }
  }

  handleFavIcon(image: PublicImageImageViewmodelGen) {
    if (image && image.cdnUrl) {
      this.reportSettings.favIconUrl = image.cdnUrl;
    }
  }

  handleWelcome(image: PublicImageImageViewmodelGen) {
    if (image && image.cdnUrl) {
      this.reportSettings.welcomeImageUrl = image.cdnUrl;
    }
  }

  defaulReportScreenOrder() {
    this.reportSettings.screenOrder = new CreatePartnerDto().defaulScreenOrder();
  }

  defaulThgScreenOrder() {
    this.reportSettings.screenOrder = new CreatePartnerDto().defaultThgScreenOrder();
  }

  defaulThgATScreenOrder() {
    this.reportSettings.screenOrder = new CreatePartnerDto().defaultThgATScreenOrder();
  }

  defaulThgScreenOrderCustomerAccount() {
    this.reportSettings.screenOrder = new CreatePartnerDto().defaultThgScreenOrderCustomerAccount();
  }

  defaulThgATScreenOrderCustomerAccount() {
    this.reportSettings.screenOrder = new CreatePartnerDto().defaultThgATScreenOrderCustomerAccount();
  }

  defaulThgScreenOrderOnlyCustomerAccount() {
    this.reportSettings.screenOrder = new CreatePartnerDto().defaultThgScreenOrderOnlyCustomerAccount();
  }

  defaulThgATScreenOrderOnlyCustomerAccount() {
    this.reportSettings.screenOrder = new CreatePartnerDto().defaultThgATScreenOrderOnlyCustomerAccount();
  }

  addWelcomeText() {
    this.reportSettings.welcomeText.push({ text: "", icon: "" });
  }

  removeWelcomeText(index: number) {
    this.reportSettings.welcomeText.splice(index, 1);
  }

  get ghgContext() {
    return ConfigModule.appContext == AppContextEnum.THG_PORTAL;
  }

  get reportContext() {
    return ConfigModule.appContext == AppContextEnum.REPORT_PORTAL;
  }

  dragged = {
    from: -1,
    to: -1,
    newIndex: -1
  };
  move(value: any) {
    this.dragged = {
      from: parseInt(value.from.id),
      to: parseInt(value.to.id),
      newIndex: value.draggedContext.futureIndex
    };
  }
  handleScreenOrderChange(value: any) {
    if (value.removed) {
      // insert
      this.reportSettings.screenOrder.splice(
        this.dragged.to + this.dragged.newIndex,
        0,
        this.reportSettings.screenOrder[this.dragged.from]
      );
      // delete
      if (this.dragged.from <= this.dragged.to)
        // LTR
        this.reportSettings.screenOrder.splice(this.dragged.from, 1);
      // RTL
      else this.reportSettings.screenOrder.splice(this.dragged.from + 1, 1);
    }
  }
}
