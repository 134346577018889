










































import { requiredRule } from "@/lib/rules/requiredRule";
import { UpdateRegistrationDto } from "@/lib/utility/updateRegistrationDto";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { UpdateVehicleRegistrationDto } from "@/models/update-vehicle-registration.dto";
import { MrfiktivUpdateVehicleRegistrationDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "./ConfirmActionDialog.vue";
import RegistrationForm from "./RegistrationForm.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import aiService from "@/services/mrfiktiv/services/aiService";


@Component({
  components: { ConfirmActionDialog, RegistrationForm }
})
export default class RegistrationUpdateDialog extends DarkModeHighlightMixin {
  dialog = false;

  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  registration!: MrfiktivUpdateVehicleRegistrationDtoGen;

  @Prop({ default: "" })
  registrationImageLink!: string;

  @Prop({ default: true })
  icon!: boolean;

  registrationModel: MrfiktivUpdateVehicleRegistrationDtoGen = new UpdateVehicleRegistrationDto(this.registration);

  zoomed = false;
  currentScale = 1;
  currentRotation = 0;

  get ActionEnum() {
    return ActionEnum;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  get rules() {
    return [requiredRule()];
  }

  get isDisabled() {
    return false;
  }

  zoomImage(event: Event) {
    const image = event.currentTarget as HTMLImageElement;

    if (!this.zoomed) {
      this.currentScale = 1.5;

      image.style.transform = "scale(" + this.currentScale + ") rotate(" + this.currentRotation + "deg)";
      image.style.transition = "0.7s ease-in-out";
      image.classList.remove("zoomOff");
      image.classList.add("zoomOn");
    } else {
      this.currentScale = 1;

      image.style.transform = "scale(" + this.currentScale + ") rotate(" + this.currentRotation + "deg)";
      image.style.transition = "0.7s ease-in-out";
      image.classList.remove("zoomOn");
      image.classList.add("zoomOff");
    }
    this.zoomed = !this.zoomed;
  }

  resetDialog() {
    this.registrationModel = new UpdateVehicleRegistrationDto(this.registration);
    this.dialog = true;
  }

  close() {
    this.resetDialog();
    this.dialog = false;
  }

  async updateRegistration() {
    this.$emit("update", new UpdateRegistrationDto(this.registrationModel));

    this.dialog = false;
  }

}
