var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.listItem
    ? _c(
        "v-list-item",
        {
          attrs: {
            disabled: _vm.disabled
          },
          on: {
            click: _vm.download
          }
        },
        [_vm._v(" Herunterladen ")]
      )
    : _vm.icon
    ? _c(
        "v-btn",
        {
          attrs: {
            disabled: _vm.disabled,
            icon: "",
            loading: _vm.loading
          },
          on: {
            click: _vm.download
          }
        },
        [
          _c(
            "v-icon",
            {
              attrs: {
                color: "grey lighten-1"
              }
            },
            [_vm._v("mdi-download")]
          )
        ],
        1
      )
    : _c(
        "v-btn",
        {
          attrs: {
            disabled: _vm.disabled,
            elevation: "0",
            "x-small": "",
            dense: "",
            outlined: _vm.outlined,
            color: "info",
            loading: _vm.loading
          },
          on: {
            click: _vm.download
          }
        },
        [_vm._v(" Herunterladen ")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }