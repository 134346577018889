































import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import ActivityTimeLineItemUpdate from "./ActivityTimeLineItemUpdate.vue";

@Component({
  components: { TimelineCard, TemplateEditor, ActivityTimeLineItemDelete, ActivityTimeLineItemUpdate, ContextMenu }
})
export default class ActivityTimeLineItemComment extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }
}
