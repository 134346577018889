








































































import { NetworkError } from "@/lib/exceptions/http/NetworkError";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import { ThgCreateAnonymousThgDtoGen } from "@/services/thg/v1/data-contracts";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import PartnerSelectionForm from "./PartnerSelectionForm.vue";
import ThgDialogYearSelection from "./ThgDialogYearSelection.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IThg } from "@/models/thg.entity";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: { PartnerSelectionForm, ThgDialogYearSelection, Debug }
})
export default class ThgAdminQuickCheckoutDialog extends Vue {
  @Prop()
  selectedUser!: IAdminUser;

  @Prop()
  thg!: IThg;

  /**
   * Form valid
   */
  isValid = false;

  /**
   * Displays loading animation
   */
  loading = false;

  /**
   * Show Dialog
   */
  isDialogActive = false;

  /**
   * The partner id that should be selected
   */
  partnerId?: string = this.thg.partnerId;

  /**
   * Check if partner is selected
   */
  isPartnerSet = this.thg.partnerId ? true : false;

  get numberplate() {
    return this.thg.numberplate;
  }

  get requiredRule() {
    return requiredRule();
  }

  get valid() {
    return this.isValid && this.isPartnerSet;
  }

  get isConfirmed() {
    return ThgCreateModule.isConfirmed;
  }

  set isConfirmed(isConfirmed: boolean) {
    ThgCreateModule.setIsConfirmed(isConfirmed);
  }

  get isTermsAccepted() {
    return ThgCreateModule.isTermsAccepted;
  }

  set isTermsAccepted(isTermsAccepted: boolean) {
    ThgCreateModule.setIsTermsAccepted(isTermsAccepted);
  }

  async startCheckout() {
    ThgCreateModule.resetThgForQuickCheckout();
    ThgCreateModule.resetRegistrationImages();
    ThgCreateModule.setYears(this.years || []);

    ThgCreateModule.setNumberplate(this.thg.numberplate);

    // We rely on report module to create THGs, so setting the partner here.
    ReportModule.setPartner(PartnerModule.partner);

    this.loading = true;
    try {
      await PartnerActiveOfferModule.loadDocuments(PartnerModule.partner._id);
      this.partnerId = PartnerModule.partner._id;
      this.isPartnerSet = true;
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchImages() {
    let frontImage = await fetch(this.thg.registrationImages[0].url);
    let backImage = await fetch(this.thg.registrationImages[1].url);

    // if access to thg images is not permitted, try to fetch THG to get access URL and try again
    if (frontImage.status === 409 || backImage.status === 409) {
      this.thg = await ThgPortalModule.getSelectedThgByPartnerID({
        partnerId: this.thg.partnerId,
        thgId: this.thg.id
      });

      frontImage = await fetch(this.thg.registrationImages[0].url);
      backImage = await fetch(this.thg.registrationImages[1].url);
    }

    return { frontImage, backImage };
  }

  /**
   * TODO: FIXME: Upload the existing registration images of a THG as new ones.
   * Download the image from URL, and convert it into a file to be "reuploaded" as a new registration image.
   *
   * Creates a new @see IImageUploaded to be used as a registration image.
   */
  async uploadExistingRegistrationDocuments() {
    const { frontImage, backImage } = await this.fetchImages();

    const frontImageBlob = await frontImage.blob();
    const backImageBlob = await backImage.blob();

    await ThgCreateModule.addRegistrationFront(
      new File([frontImageBlob], "front.jpg", { type: "image/jpg", lastModified: new Date().getTime() })
    );
    await ThgCreateModule.addRegistrationBack(
      new File([backImageBlob], "back.jpg", { type: "image/jpg", lastModified: new Date().getTime() })
    );
  }

  async savePartner(partnerId: string) {
    this.$log.debug(this.partnerId);

    this.loading = true;
    try {
      await PartnerActiveOfferModule.loadDocuments(partnerId);
      this.partnerId = partnerId;
      this.isPartnerSet = true;
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  changePartner() {
    this.isPartnerSet = false;
  }

  /**
   * Creates a new THG based on the parent.
   * Uses the ThgCreateStore to set the items.
   */
  async quickCheckout() {
    this.loading = true;

    if (!this.partnerId) {
      this.$toast.error("Partner missing");
      return;
    }

    try {
      await this.uploadExistingRegistrationDocuments();

      const thgQuote: ThgCreateAnonymousThgDtoGen = {
        impactType: "payout",
        impactFactor: 0,
        numberplate: this.thg.numberplate,
        registrationImages: [
          ThgCreateModule.registrationImageFront[0].uploadId,
          ThgCreateModule.registrationImageBack[0].uploadId
        ],
        createUserDto: {
          userName: this.selectedUser?.userName ?? "",
          firstName: this.selectedUser?.firstName ?? "",
          lastName: this.selectedUser?.lastName ?? ""
        },
        years: ThgCreateModule.years,
        registration: this.thg.registration
      };

      const thg = await ThgCreateModule.createAnonymous({ partnerId: this.partnerId, thgQuote });

      this.$toast.success("🤩 👍");

      ThgCreateModule.resetThgForQuickCheckout();
      ThgCreateModule.resetRegistrationImages();

      // Lets hack this away
      await ThgPortalModule.getThgByPartnerId(PartnerModule.partner.id || PartnerModule.partner._id);

      await this.close();

      await new GoToHelper(this.$router).goToThgDetail(thg.partnerId, thg.id);
    } catch (error) {
      if (error instanceof NetworkError) {
        this.$toast.error("Fehler mit der Verbindung. Bitte zu einem späteren Zeitpunkt erneut versuchen.");
      } else {
        this.$toast.error("Fehler beim senden. Bitte Eingaben noch einmal prüfen.");
      }
    } finally {
      this.loading = false;
    }
  }

  async close() {
    this.isDialogActive = false;
    this.$emit("refresh");
  }

  get prices() {
    return PartnerActiveOfferModule.prices;
  }

  get years(): number[] {
    return this.prices.filter(p => p.year !== this.thg.year).map(p => p.year);
  }
}
