import { IAttachmentRequest } from "@/models/attachment-request.entity";
import { ISharedContent } from "@/models/shared-content.entity";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";

export class SharingLinkFactory {
  sharedContent: ISharedContent | IAttachmentRequest;
  isEdit: boolean;

  constructor(sharedContent: ISharedContent | IAttachmentRequest, isEdit: boolean) {
    this.sharedContent = sharedContent;
    this.isEdit = isEdit;
  }

  /**
   * Creates the url to the report and appends the specified query params
   */
  get createUrlWithQuery() {
    if (!this.isEdit) {
      return `${ConfigModule.portalUrl}/partners/${this.sharedContent.partnerId}/shared/${this.sharedContent.id}/access/${this.sharedContent.token}`;
    } else {
      return `https://${PartnerModule.partner.settings?.defaultUrl}/#/request/${this.sharedContent.id}/${this.sharedContent.token}`;
    }
  }

  copyLinkToClipboard() {
    navigator.clipboard.writeText(this.createUrlWithQuery);
  }
}
