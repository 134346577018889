import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import {
  MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen,
  MrfiktivPartnerMessageViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import messageService from "@/services/shared/messageService";
import {
  ThgPartnerMessageControllerFindAllByPartnerIdParamsGen,
  ThgPartnerMessageViewModelGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, getModule } from "vuex-module-decorators";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

@Module({
  dynamic: true,
  namespaced: true,
  name: "messagePagination",
  store
})
export class PartnerMessagePaginationStore extends BasePagination<
  MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen,
  ThgPartnerMessageControllerFindAllByPartnerIdParamsGen | MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: MrfiktivPartnerMessageViewModelGen[] | ThgPartnerMessageViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = false;
  filterOptions: PaginationFilterListElement[] = [{ key: "_id", type: PageFilterTypes.OBJECT_ID }].map(
    f => new PaginationFilterListElement(f)
  );

  @Action
  protected async loadDocuments(query: any): Promise<any> {
    return messageService.getAllPaginated({
      ...query
    });
  }
}

export const PartnerMessagePaginationModule = getModule(PartnerMessagePaginationStore);
