import i18n from "../../plugins/I18nPlugin";

/**
 * @see https://emailregex.com/
 * @param message the message to display
 * @returns validates an email
 */
export function emailRule(message = "report.formPersonalData.validEmail"): (value: any) => boolean | string {
  const regex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  return value => regex.test(value) || String(i18n.tc(message));
}

export function disallowUmlaut(message = "report.formPersonalData.umlaut"): (value: any) => boolean | string {
  const regex = RegExp(/[äöüÄÖÜ]/);

  return value => !regex.test(value) || String(i18n.tc(message));
}

export function emailRuleOptional(message = "report.formPersonalData.validEmail"): (value: any) => boolean | string {
  const regex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return value => regex.test(value) || value == "" || String(i18n.tc(message));
}

/*
  Value shouldn't contain a single number.
*/
export function noNumberRule(message = "report.formPersonalData.validValue"): (value: any) => boolean | string {
  const regex = RegExp(/^([^0-9]*)$/);
  return value => regex.test(value) || String(i18n.tc(message));
}

/*
Value can only be a 5 digit number between 1000 and 99999
*/
export function germanZipRule(message = "report.formPersonalData.validZip"): (value: any) => boolean | string {
  const regex = RegExp(/^\d{4,5}$/);

  return value => (regex.test(value) && (value > 1000 || value < 99999)) || !value || String(i18n.tc(message));
}

/*
  Match a single character present in the list below [+]
  ? matches the previous token between zero and one times, as many times as possible, giving back as needed (greedy)
  + matches the character + literally (case sensitive)
  Match a single character present in the list below [0-9\s]
  * matches the previous token between zero and unlimited times, as many times as possible, giving back as needed (greedy)
  0-9 matches a single character in the range between 0 (index 48) and 9 (index 57) (case sensitive)
  \s matches any whitespace character (equivalent to [\r\n\t\f\v ])
*/
export function phoneRule(message = "report.formPersonalData.validPhone"): (value: any) => boolean | string {
  const regex = RegExp(/^[+]?[0-9\s]*$/);
  return value => regex.test(value) || String(i18n.tc(message));
}

/*
  Match a single character present in the list below [+]
  ? matches the previous token between zero and one times, as many times as possible, giving back as needed (greedy)
  + matches the character + literally (case sensitive)
  Match a single character present in the list below [0-9\s]
  * matches the previous token between zero and unlimited times, as many times as possible, giving back as needed (greedy)
  0-9 matches a single character in the range between 0 (index 48) and 9 (index 57) (case sensitive)
  \s matches any whitespace character (equivalent to [\r\n\t\f\v ])
  can be empty
*/
export function phoneRuleOptional(message = "report.formPersonalData.validPhone"): (value: any) => boolean | string {
  const regex = RegExp(/^[+]?[0-9\s]*$/);
  return value => regex.test(value) || value == "" || String(i18n.tc(message));
}

/*
  Match a single character present in the list below [+-]
  ? matches the previous token between zero and one times, as many times as possible, giving back as needed (greedy)
  + matches the character + literally (case sensitive)
  Match a single character present in the list below [0-9\s]
  * matches the previous token between zero and unlimited times, as many times as possible, giving back as needed (greedy)
  0-9 matches a single character in the range between 0 (index 48) and 9 (index 57) (case sensitive)
  \s matches any whitespace character (equivalent to [\r\n\t\f\v ])

  Used to update the profile (- in phone numer valid)
*/
export function extendetPhoneRule(message = "report.formPersonalData.validPhone"): (value: any) => boolean | string {
  const regex = RegExp(/^[+-]?[0-9\s]*$/);
  return value => regex.test(value) || String(i18n.tc(message));
}
