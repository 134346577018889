












































import NumberplateForm from "@/components/utility/NumberplateForm.vue";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { MrfiktivUpdateReportDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import VehicleApiSelection from "../utility/VehicleApiSelection.vue";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Registration } from "@/models/registration.entity";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";

@Component({
  components: {
    Card,
    VehicleApiSelection,
    NumberplateForm
  }
})
export default class PartnerReportCardGeneral extends Vue {
  @Ref("vehicle-api-selection")
  selection!: VehicleApiSelection;

  @Prop()
  private dto!: MrfiktivUpdateReportDtoGen | CreateReportAsPartnerDto;

  @Prop()
  value!: boolean;

  ActionEnum = ActionEnum;

  ResourceEnum = ResourceEnum;

  loadingVehicle = false;

  vehicle: IVehicle | null = null;

  key = 0;

  get countryCode() {
    return PartnerModule?.partner?.countryCode ?? CountryCodeEnum.germany;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get isFleetPartner() {
    return PartnerModule.partner.partnerType === PartnerTypeEnum.FLEET;
  }

  get isValid() {
    return this.value;
  }

  set isValid(v: boolean) {
    this.$emit("input", v);
  }

  get reportDto() {
    return this.dto;
  }

  set reportDto(value) {
    this.$emit("update:dto", value);
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get isNumberRule() {
    return [isNumberRule()];
  }

  onVehicleSelect(vehicle: IVehicle) {
    const registrationDto = new Registration({
      ...vehicle?.registration,
      numberplate: vehicle?.numberplate ?? vehicle?.registration?.numberplate,
      identificationnumber: vehicle?.identificationnumber ?? vehicle?.registration?.identificationnumber
    });

    this.reportDto.vehicleId = vehicle?.id;
    this.reportDto.numberplate = vehicle.numberplate ?? this.reportDto.numberplate;
    this.reportDto.registrationResults = registrationDto;
  }

  async mounted() {
    await this.getVehicle();
  }

  async getVehicle() {
    const vehicleId = this.reportDto.vehicleId;
    if (vehicleId) {
      try {
        this.loadingVehicle = true;

        // get vehicle from store or backend
        let vehicle = VehicleModule.maps.id.get(vehicleId)[0];
        if (!vehicle) {
          vehicle = await VehicleModule.getOne({
            partnerId: this.partnerId,
            vehicleId: vehicleId
          });
        }
        this.vehicle = vehicle;

        // preset vehicle in selection dropdown if found
        this.$nextTick(() => {
          if (this.vehicle && this.selection) {
            this.selection.selected = this.vehicle?.id;
            if (!this.selection.items?.find(i => i.id === this.vehicle?.id)) {
              this.selection.items?.push(this.vehicle);
            }
          }
        });
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.loadingVehicle = false;
      }
    }
  }
}
