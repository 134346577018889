











































































































import CustomField from "@/components/report/CustomField.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { MrfiktivCustomFieldViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CustomFieldHelper } from "@/store/modules/custom-field.store";
import Fuse from "fuse.js";
import { Component, Prop, Watch } from "vue-property-decorator";
import Debug from "../Debug.vue";
import { VTextarea, VAutocomplete } from "vuetify/lib/components";
import { $t } from "@/lib/utility/t";
import { getNestedObjectValues, propertiesToArray } from "@/lib/objectPath-helper";
import Vue from "vue";
import PartnerSingleImageUpload from "../PartnerSingleImageUpload.vue";
import RefsSelect from "../RefsSelect.vue";
import { IMDetailFormConfig } from "@/lib/formable";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";

/**
 * Factory to configure a form configuration
 */
export class MDetailFormConfigFactory<T extends Record<string, any>> {
  /**
   * Default category in which every item is placed initially. Can be updated via setCategory for each items
   * @example General, Allgemiaon
   */
  private readonly BASE_CAT = $t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.generalTitle");

  /**
   * Default type of every item. Can be updated via setType for each item
   * @default v-text-field
   */
  private readonly BASE_TYPE = DetailFormComponentsEnum.TEXT_FIELD;

  /**
   * Map of all configs
   * key: key of the config
   * value: config
   */
  private readonly configs = new Map<string, IMDetailFormConfig>();

  /**
   * @param model the object that should be used to create the form
   * @param keys subset of keys that should be used to create the form. If not set all keys of the model are used
   * @param i18nPath path to the i18n file where the labels are stored
   */
  constructor(private readonly model: T, keys?: string[], private readonly i18nPath = "") {
    for (const key of keys ? keys : propertiesToArray(this.model)) {
      const label = $t(this.i18nPath ? [this.i18nPath, key].join(".") : key);

      this.configs.set(key, {
        category: this.BASE_CAT,
        key: key,
        searchKeywords: [this.BASE_CAT, label],
        type: this.BASE_TYPE,
        model: getNestedObjectValues(this.model, key),
        props: {
          label: label
        }
      });
    }
  }

  /**
   * Set the category of the config. Category should be the title of the group
   */
  setCategory(key: string, category: string) {
    const config = this.configs.get(key);

    if (!config) {
      Vue.$log.error(new Error(`Config with key ${key} not found`));

      return this;
    }

    config.category = category;
    config.searchKeywords[0] = category;

    return this;
  }

  /**
   * Set the type of the config. Type defines how the component is rendered
   * default is v-text-field
   */
  setType(key: string, type: DetailFormComponentsEnum) {
    const config = this.configs.get(key);

    if (!config) {
      Vue.$log.error(new Error(`Config with key ${key} not found`));

      return this;
    }

    config.type = type;

    return this;
  }

  /**
   * Add props to the config
   * this can be used to customize the component depending on the type
   */
  addProps(key: string, props: any) {
    const config = this.configs.get(key);

    if (!config) {
      Vue.$log.error(new Error(`Config with key ${key} not found`));

      return this;
    }

    config.props = { ...config.props, ...props };

    return this;
  }

  /**
   * get the list of configs as configured
   */
  create() {
    return Array.from(this.configs.values());
  }
}

interface IMDetailFormClusterConfig {
  category: string;
  items: IMDetailFormConfig[];
}

type CustomFieldItem = {
  id: string;
  value: number | boolean | string;
};

@Component({
  components: {
    Debug,
    CustomField,
    VTextarea,
    VAutocomplete,
    PartnerSingleImageUpload,
    RefsSelect
  }
})
export default class<T extends { [key in keyof T]: CustomFieldItem[] }> extends RulesMixin {
  CUSTOM_FIELD = "custom";

  /**
   * Title of the table
   */
  @Prop()
  title?: string;

  /**
   * Description of the table
   */
  @Prop()
  description?: string;

  /**
   * Item which data should be displayed
   */
  @Prop()
  item!: T;

  /**
   * Config of the tablev-text
   */
  @Prop()
  config!: IMDetailFormConfig[];

  /**
   * Form is disabled completly
   */
  @Prop({ default: false })
  disabled!: boolean;

  /**
   * Form is readonly
   */
  @Prop({ default: false })
  readonly!: boolean;

  /**
   * key where the custom fields are in the object
   */
  @Prop({ default: "values" })
  customFieldKey!: Extract<keyof T, string>;

  /**
   * Available custom fields for this form
   */
  @Prop()
  customFields!: MrfiktivCustomFieldViewModelGen[];

  /**
   * Function that sync changes
   * You have to map the config to your object to update your object changes
   */
  @Prop()
  syncChanges!: () => Promise<void>;

  /**
   * Function to abort changes
   * You have to map the object to your config to abort your changes
   */
  @Prop()
  abortChanges!: () => Promise<void>;

  /**
   * Count of extra panels passed in the extraPanels slot
   * needed to open all panels
   */

  @Prop({ default: 0 })
  extraPanelsCount!: number;

  @Prop({ default: false })
  containButtons!: boolean;

  search = "";

  /**
   * If a change of an input field is detected changes detected is set to true
   */
  changesDetected = false;

  /**
   * open panels
   */
  panels: number[] = [];

  /**
   * Used to reevaluate conditions
   */
  inputKey = 0;

  isValid = true;

  countObjectsForCategory(config: IMDetailFormConfig[], categoryName: string): number {
    return config.filter(item => item.category === categoryName).length;
  }

  beforeMount() {
    for (const config of this.config) {
      // sometimes the items of a select field are not available/ are different on configuration time than on mounted
      if (
        (config.type === DetailFormComponentsEnum.SELECT_FIELD ||
          config.type === DetailFormComponentsEnum.AUTO_COMPLETE) &&
        config.props?.getItems
      ) {
        config.props.items = config.props.getItems();
      }

      if (config.props.getPartnerId) {
        config.props.partnerId = config.props.getPartnerId();
      }
    }

    if (this.customFields) {
      for (const customField of this.customFields) {
        const found = this.item[this.customFieldKey].find(c => c.id === customField.id);

        this.config.push({
          category: this.CUSTOM_FIELD,
          key: customField.id,
          type: DetailFormComponentsEnum.CUSTOM_FIELD,
          model: found?.value || "",
          searchKeywords: [customField.name],
          props: {
            customField: new CustomFieldHelper(customField)
          }
        });
      }
    }
  }

  /**
   * Go through the passed config and translate the properties again.
   * This ensures we always show translated keys no matter if the parent component translated them or not.
   */
  @Watch("$i18n.locale", { immediate: true, deep: false })
  onLocaleChanged() {
    for (const config of this.config) {
      this.translateConfig(config);
    }
  }

  mounted() {
    this.openAllPanels();
  }

  onInput() {
    this.inputKey++;
    this.validate();
    this.changesDetected = true;
  }

  async sync() {
    const customConfig = this.config.filter(c => c.category === this.CUSTOM_FIELD);
    for (const config of customConfig) {
      const found = this.item[this.customFieldKey].find(v => v.id === config.key);
      if (config.model) {
        if (found) {
          // change value if custom field already exists on object
          found.value = config.model as any;
        } else {
          // adds custom field if not exists
          this.item[this.customFieldKey].push({ id: config.key, value: config.model as string });
        }
      } else {
        // Remove the object from the array when config.model is falsy (null or undefined)
        (this.item[this.customFieldKey] as CustomFieldItem[]) = this.item[this.customFieldKey].filter(
          v => v.id !== config.key
        );
      }
    }
    await this.syncChanges();
    this.changesDetected = false;
  }

  async abort() {
    const customConfig = this.config.filter(c => c.category === this.CUSTOM_FIELD);
    for (const config of customConfig) {
      config.model = this.item[this.customFieldKey].find(v => v.id === config.key)?.value || "";
    }
    await this.abortChanges();
    this.changesDetected = false;
  }

  /**
   * Clusters the field by key to show groupded form fields
   */
  get fields(): IMDetailFormClusterConfig[] {
    let fields: IMDetailFormConfig[] = [];
    this.panels = [];

    if (this.search) {
      for (const formField of this.filteredList) {
        fields.push(formField.item);
      }
    } else {
      fields = this.config;
    }

    const clusteredFields: IMDetailFormClusterConfig[] = [];
    for (const field of fields) {
      const found = clusteredFields.find((c: IMDetailFormClusterConfig) => c.category === field.category);
      if (found) {
        found.items.push(field);
      } else {
        clusteredFields.push({ category: field.category, items: [field] });
      }
    }
    this.createOpenPanelsArray(clusteredFields.length);

    return clusteredFields;
  }

  get filteredList() {
    const fuseOptions = {
      threshold: 0.35,
      keys: ["searchKeywords"]
    };

    const fuse = new Fuse(this.config, fuseOptions);

    return fuse.search(this.search);
  }

  createOpenPanelsArray(numberPanels: number) {
    this.panels = Array.from({ length: numberPanels }, (_, index) => index);
  }

  openAllPanels() {
    this.createOpenPanelsArray(this.fields.length + this.extraPanelsCount);
  }

  validate() {
    for (const config of this.config) {
      if (config.props.rules) {
        // if condition is unsatisfied fiel is not rendered and not part of form validation
        if (!this.getIsConditionSatisfied(config.condition)) continue;

        for (const rule of config.props.rules) {
          const isValid = rule(config.model);
          if (isValid !== true) {
            this.isValid = false;
            return;
          }
        }
      }
    }
    this.isValid = true;
  }

  getClusterFieldForKey(key: string) {
    for (const cluster of this.fields) {
      const found = cluster.items.find(c => c.key === key);
      if (found) return found;
    }
  }

  getIsConditionSatisfied(condition?: { key: string; value: string }) {
    if (!condition) return true;

    const field = this.getClusterFieldForKey(condition.key);

    return field?.model === condition.value;
  }

  /**
   * Translate certain properties of the IMDetailFormConfig object
   * @param config the config with keys for translation.
   * Examples are: props.label, category and the searchKeywords array.
   * This mutates the config object passed.
   */
  private translateConfig(config: IMDetailFormConfig<any>) {
    // translate label
    if (config.props?.label) {
      config.props.label = $t(config.props.label);
    }

    // translate search strings
    config.searchKeywords = config.searchKeywords?.map(keyword => $t(keyword));

    // translate category
    config.category = $t(config.category);
  }
}
