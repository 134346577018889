import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportType } from "@/store/enum/reportType";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";

export interface IInitReport {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  zip: string;
  street: string;
  city: string;
  numberPlate: string;
  registration_identificationnumber?: string;
  externalId: string;
  reportType: string;
}

enum UrlParams {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PHONE = "phone",
  EMAIL = "email",
  NUMBER_PLATE = "numberPlate",
  REPORT_TYPE = "reportType",
  CITY = "city",
  STREET = "street",
  ZIP = "zip",
  EXTERNAL_ID = "externalId",
  TYPE = "type",
  DA_SERVICE_EVENT_ID = "serviceEventId",
  KSR_JOB_ID = "jobId",

  REGISTRATION_FIRSTNAME = "registration_firstname",
  REGISTRATION_NAME = "registration_name",
  REGISTRATION_STREET = "registration_street",
  REGISTRATION_ZIPCODE = "registration_zipCode",
  REGISTRATION_CITY = "registration_city",
  REGISTRATION_HUYEAR = "registration_huYear",
  REGISTRATION_HUMONTH = "registration_huMonth",
  REGISTRATION_MANUFACTURERNAMECODE = "registration_manufacturerNameCode",
  REGISTRATION_MANUFACTURERTYPECODE = "registration_manufacturerTypeCode",
  REGISTRATION_DRIVETYP = "registration_driveTyp",
  REGISTRATION_IDENTIFICATIONNUMBER = "registration_identificationnumber",
  REGISTRATION_NUMBERPLATE = "registration_numberplate",
  REGISTRATION_FIRSTREGISTRATIONDAY = "registration_firstregistrationDay",
  REGISTRATION_FIRSTREGISTRATIONMONTH = "registration_firstregistrationMonth",
  REGISTRATION_FIRSTREGISTRATIONYEAR = "registration_firstregistrationYear",
  REGISTRATION_MANUFACTURERNAME = "registration_manufacturerName",
  REGISTRATION_MANUFACTURERTYPE = "registration_manufacturerType",
  REGISTRATION_VEHICLECLASS = "registration_vehicleClass"
}

export class CreateReportUrlFactory implements IInitReport {
  /**
   * The customerEmail to prefill the report
   */
  email = "";

  /**
   * The firstName to prefill the report
   */
  firstName = "";

  /**
   * The lastName to prefill the report
   */
  lastName = "";

  /**
   * The phone to prefill the report
   */
  phone = "";

  /**
   * The zip to prefill the report
   */
  zip = "";

  /**
   * The street to prefill the report
   */
  street = "";

  /**
   * The city to prefill the report
   */
  city = "";

  /**
   * The numberplate to prefill the report
   */
  numberPlate = "";

  /**
   * External ID to allocate to an internal item
   */
  externalId = "";

  /**
   * Type of the report
   */
  reportType = "";

  /**
   * The Base URL
   */
  baseUrl = "";

  /**
   * The service event id
   */
  serviceEventId = "";

  /**
   * The ksr job id
   */
  jobId = "";

  registration_firstname = "";
  registration_name = "";
  registration_street = "";
  registration_zipCode = "";
  registration_city = "";
  registration_huYear = "";
  registration_huMonth = "";
  registration_manufacturerNameCode = "";
  registration_manufacturerTypeCode = "";
  registration_driveTyp = "";
  registration_identificationnumber = "";
  registration_numberplate = "";
  registration_firstregistrationDay = "";
  registration_firstregistrationMonth = "";
  registration_firstregistrationYear = "";
  registration_manufacturerName = "";
  registration_manufacturerType = "";
  registration_vehicleClass = "";

  constructor(initReport?: IInitReport) {
    if (initReport) {
      this.email = initReport.email;
      this.firstName = initReport.firstName;
      this.lastName = initReport.lastName;
      this.phone = initReport.phone;
      this.zip = initReport.zip;
      this.street = initReport.street;
      this.city = initReport.city;
      this.numberPlate = initReport.numberPlate;
      this.externalId = initReport.externalId;
      this.reportType = initReport.reportType;
      this.registration_identificationnumber = initReport.registration_identificationnumber || "";
    }
  }

  setFromCreateDialog(dto?: CreateReportAsPartnerDto) {
    this.email = this.email || dto?.customerContact?.email || "";
    this.phone = this.phone || dto?.customerContact?.phone || "";
    this.firstName = this.firstName || dto?.firstName || "";
    this.lastName = this.lastName || dto?.lastName || "";
    this.zip = this.zip || dto?.customerAddress?.zip || "";
    this.street = this.street || dto?.customerAddress?.street || "";
    this.city = this.city || dto?.customerAddress?.city || "";
    this.numberPlate = this.numberPlate || dto?.numberplate || dto?.registrationResults?.numberplate || "";
    this.externalId = this.externalId || dto?.externalId || "";
    this.reportType = this.reportType || dto?.reportType || "";

    this.registration_firstname = this.registration_firstname || dto?.registrationResults?.firstname || "";
    this.registration_name = this.registration_name || dto?.registrationResults?.name || "";
    this.registration_street = this.registration_street || dto?.registrationResults?.street || "";
    this.registration_zipCode = this.registration_zipCode || dto?.registrationResults?.zipCode || "";
    this.registration_city = this.registration_city || dto?.registrationResults?.city || "";
    this.registration_huYear = this.registration_huYear || dto?.registrationResults?.huYear || "";
    this.registration_huMonth = this.registration_huMonth || dto?.registrationResults?.huMonth || "";
    this.registration_manufacturerNameCode =
      this.registration_manufacturerNameCode || dto?.registrationResults?.manufacturerNameCode || "";
    this.registration_manufacturerTypeCode =
      this.registration_manufacturerTypeCode || dto?.registrationResults?.manufacturerTypeCode || "";
    this.registration_driveTyp = this.registration_driveTyp || dto?.registrationResults?.driveTyp || "";
    this.registration_identificationnumber =
      this.registration_identificationnumber || dto?.registrationResults?.identificationnumber || "";
    this.registration_numberplate = this.registration_numberplate || dto?.registrationResults?.numberplate || "";
    this.registration_firstregistrationDay =
      this.registration_firstregistrationDay || dto?.registrationResults?.firstregistrationDay || "";
    this.registration_firstregistrationMonth =
      this.registration_firstregistrationMonth || dto?.registrationResults?.firstregistrationMonth || "";
    this.registration_firstregistrationYear =
      this.registration_firstregistrationYear || dto?.registrationResults?.firstregistrationYear || "";
    this.registration_manufacturerName =
      this.registration_manufacturerName || dto?.registrationResults?.manufacturerName || "";
    this.registration_manufacturerType =
      this.registration_manufacturerType || dto?.registrationResults?.manufacturerType || "";
    this.registration_vehicleClass = this.registration_vehicleClass || dto?.registrationResults?.vehicleClass || "";

    return this;
  }

  /**
   * Creates the url to the report and appends the specified query params
   */
  url(baseUrl: string): string {
    let url = "https://" + baseUrl + "/#/" + "?";

    for (const prop of Object.values(UrlParams)) {
      url += this.appendQuery(prop, this[prop]);
    }

    return url.slice(0, -1); // remove trailing &
  }

  /**
   * Appends an query to an Url
   */
  appendQuery(queryParamName: string, value: string): string {
    if (value) {
      return queryParamName + "=" + encodeURI(value) + "&";
    } else {
      return "";
    }
  }

  parseUrl(url: string) {
    const queryBits = url.split("/#/");
    const query = queryBits[1] ?? queryBits[0];
    const queryParams = new URLSearchParams(query);

    this.email = queryParams.get(UrlParams.EMAIL) ?? "";
    this.firstName = queryParams.get(UrlParams.FIRST_NAME) ?? "";
    this.lastName = queryParams.get(UrlParams.LAST_NAME) ?? "";
    this.phone = queryParams.get(UrlParams.PHONE) ?? "";
    this.zip = queryParams.get(UrlParams.ZIP) ?? "";
    this.street = queryParams.get(UrlParams.STREET) ?? "";
    this.city = queryParams.get(UrlParams.CITY) ?? "";
    this.numberPlate = queryParams.get(UrlParams.NUMBER_PLATE) ?? "";
    this.externalId = queryParams.get(UrlParams.EXTERNAL_ID) ?? "";
    this.reportType = queryParams.get(UrlParams.TYPE) ?? "";
  }

  getReport(): MrfiktivReportViewModelGen {
    return {
      _id: "",
      id: "",
      firstName: this.firstName,
      lastName: this.lastName,
      customerName: `${this.firstName} ${this.lastName}`,
      customerContact: {
        email: this.email,
        phone: this.phone
      },
      numberplate: this.numberPlate,
      externalId: this.externalId,
      reportType: this.reportType as ReportType,
      customerAddress: {
        city: this.city,
        street: this.street,
        zip: this.zip,
        state: ""
      },
      datePreference: "",
      damage: [],
      marketingOptIn: false,
      progressStatus: "new",
      partnerId: "",
      message: "",
      timestamps: { created: new Date().toISOString(), lastModified: new Date().toISOString(), modified: [] },
      timestamp: { created: new Date().toISOString(), lastModified: new Date().toISOString(), modified: [] },
      images: { cockpits: [], damages: [], damagesDetail: [], overviews: [], plates: [], registrations: [] },
      registrationResults: {},
      attachment: [],
      assignees: [],
      assigneesDetails: []
    };
  }
}
