var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-timeline-item",
        {
          attrs: {
            small: "",
            icon: "mdi-target",
            "fill-dot": ""
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.partner.PartnerReportDetailInfoCard.damageLocator.title"
                )
              ) +
              " "
          )
        ]
      ),
      _vm.damageLocation && _vm.damageLocation.length
        ? _c(
            "v-timeline-item",
            {
              attrs: {
                "hide-dot": ""
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mt-4 mb-8",
                      staticStyle: {
                        overflow: "hidden"
                      },
                      attrs: {
                        flat: "",
                        "min-width": _vm.getDamageLocatorWidth,
                        "max-height": "300px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "center"
                        },
                        [
                          _vm.renderComponent
                            ? _c("damage-locator-combined", {
                                ref: "DamageLocator",
                                attrs: {
                                  isEdit: false,
                                  reportType: _vm.report.reportType,
                                  clickedColor: "#6fb38a",
                                  "damage-locations": _vm.damageLocation
                                },
                                on: {
                                  damageLocation: _vm.forceRerender
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.report.position
        ? _c(
            "v-timeline-item",
            {
              attrs: {
                "hide-dot": ""
              }
            },
            [
              _c("m-image-marker", {
                attrs: {
                  deactivated: true,
                  src: _vm.report.position.blueprint.image.url,
                  markers: [
                    {
                      left: _vm.report.position.coordinates.x,
                      top: _vm.report.position.coordinates.y
                    }
                  ]
                }
              }),
              _vm.report.position.meta
                ? _c(
                    "div",
                    {
                      staticClass: "mt-4 px-2",
                      staticStyle: {
                        "white-space": "pre-wrap"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.report.position.meta) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }