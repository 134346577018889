import { render, staticRenderFns } from "./PartnerReportDetail.vue?vue&type=template&id=0e743274&scoped=true&"
import script from "./PartnerReportDetail.vue?vue&type=script&lang=ts&"
export * from "./PartnerReportDetail.vue?vue&type=script&lang=ts&"
import style0 from "./PartnerReportDetail.vue?vue&type=style&index=0&id=0e743274&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e743274",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAvatar,VCard,VChip,VCol,VContainer,VDivider,VFlex,VImg,VLayout,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VSelect,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0e743274')) {
      api.createRecord('0e743274', component.options)
    } else {
      api.reload('0e743274', component.options)
    }
    module.hot.accept("./PartnerReportDetail.vue?vue&type=template&id=0e743274&scoped=true&", function () {
      api.rerender('0e743274', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partner/PartnerReportDetail.vue"
export default component.exports