



















































































import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip
  }
})
export default class PartnerDetailGeneralCard extends Vue {
  virtualScrollerHeight = 0;
  CountryCodeEnum = CountryCodeEnum;

  get partner() {
    return PartnerModule.partner;
  }

  get partners() {
    return PartnerModule.partners;
  }

  get agbUrl() {
    if (!this.partner.settings?.agbUrl) {
      return { text: "", link: "" };
    }

    return this.partner.settings.agbUrl;
  }

  getFlag(countryCode: string) {
    return getFlagEmoji(countryCode);
  }
}
