var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.report.user
        ? _c(
            "v-timeline-item",
            {
              attrs: {
                "fill-dot": "",
                color: "primary",
                large: ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function fn() {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "white--text"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.report.user.firstName[0] +
                                    _vm.report.user.lastName[0]
                                )
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3075270732
              )
            },
            [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(_vm.report.user.firstName) +
                    " " +
                    _vm._s(_vm.report.user.lastName) +
                    " "
                )
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "timeLine.PartnerReportDetailInfoTimeLineCard.title",
                      {
                        date: _vm.date
                      }
                    )
                  ) +
                  " "
              )
            ]
          )
        : _c(
            "v-timeline-item",
            {
              attrs: {
                "fill-dot": "",
                color: "primary",
                large: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function fn() {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "white--text"
                        },
                        [_vm._v(" " + _vm._s(_vm.customerNameInitial))]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("b", [_vm._v(" " + _vm._s(_vm.report.customerName) + " ")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "timeLine.PartnerReportDetailInfoTimeLineCard.title",
                      {
                        date: _vm.date
                      }
                    )
                  ) +
                  " "
              )
            ]
          ),
      _vm.report.customerName ||
      _vm.report.isCompany ||
      _vm.report.companyName ||
      _vm.report.isTaxDeductible ||
      _vm.report.taxnumber ||
      _vm.report.customerContact ||
      _vm.report.customerAddress
        ? _c("customer-contact-time-line-element", {
            attrs: {
              customerName: _vm.report.customerName,
              isTaxDeductible: _vm.report.isTaxDeductible,
              companyName: _vm.report.companyName,
              isCompany: _vm.report.isCompany,
              taxnumber: _vm.report.taxnumber,
              email: _vm.report.customerContact
                ? _vm.report.customerContact.email
                : undefined,
              phone: _vm.report.customerContact
                ? _vm.report.customerContact.phone
                : undefined,
              address: _vm.report.customerAddress
            }
          })
        : _vm._e(),
      _vm.isFleetPartner
        ? _c(
            "Can",
            {
              attrs: {
                I: _vm.ActionEnum.READ,
                a: _vm.ResourceEnum.VEHICLE
              }
            },
            [
              _c("vehicle-time-line-element", {
                key: "vehicle-time-line-element" + _vm.report.vehicleId,
                attrs: {
                  report: _vm.report
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.report.policeDetails
        ? _c("report-police-details-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _vm.report.preExistingDamageDetails
        ? _c("report-pre-existing-damage-details-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _vm.damageLocation
        ? _c(
            "div",
            [
              _vm.damageLocation.length
                ? _c(
                    "v-timeline-item",
                    {
                      attrs: {
                        small: "",
                        icon: "mdi-target",
                        "fill-dot": ""
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.partner.PartnerReportDetailInfoCard.damageLocator.title"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.damageLocation.length
                ? _c(
                    "v-timeline-item",
                    {
                      attrs: {
                        "hide-dot": ""
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "mt-4 mb-8",
                              staticStyle: {
                                overflow: "hidden"
                              },
                              attrs: {
                                flat: "",
                                "min-width": _vm.getDamageLocatorWidth,
                                "max-height": "300px"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "center"
                                },
                                [
                                  _vm.renderComponent
                                    ? _c("damage-locator-combined", {
                                        ref: "DamageLocator",
                                        attrs: {
                                          isEdit: false,
                                          reportType: _vm.report.reportType,
                                          clickedColor: "#6fb38a",
                                          "damage-locations": _vm.damageLocation
                                        },
                                        on: {
                                          damageLocation: _vm.forceRerender
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.report.datePreference
        ? _c(
            "v-timeline-item",
            {
              attrs: {
                small: "",
                icon: "mdi-calendar-star",
                "fill-dot": ""
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "timeLine.PartnerReportDetailInfoTimeLineCard.dateRequest",
                      {
                        name: _vm.report.customerName,
                        date: _vm.datePreference
                      }
                    )
                  ) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _vm.report.message
        ? _c(
            "timeline-card",
            {
              attrs: {
                title: _vm.$t(
                  "timeLine.PartnerReportDetailInfoTimeLineCard.message",
                  {
                    user: _vm.report.customerName
                  }
                )
              },
              scopedSlots: _vm._u(
                [
                  _vm.canUpdateReport
                    ? {
                        key: "menu",
                        fn: function fn() {
                          return [
                            _c("partner-report-update-report-dialog", {
                              attrs: {
                                config: [_vm.ReportUpdateTabEnum.message],
                                activityTarget: "message"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              )
            },
            [
              _c("p", {
                staticClass: "pt-3 px-4",
                domProps: {
                  textContent: _vm._s(_vm.report.message)
                }
              })
            ]
          )
        : _vm._e(),
      _vm.report.accidentDetails
        ? _c("partner-accident-details-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _c("partner-report-detail-image-time-line-element", {
        attrs: {
          images: _vm.getImages,
          report: _vm.report
        }
      }),
      _vm.report.insurances && _vm.report.insurances.length
        ? _c("partner-insurance-details-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _vm.report.registrationResults
        ? _c("partner-registration-time-line-element", {
            attrs: {
              report: _vm.report,
              loading: _vm.registrationUpdateLoading
            },
            on: {
              update: _vm.updateRegistration
            }
          })
        : _vm._e(),
      _vm.witness.length
        ? _c("partner-report-detail-info-witness-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _vm.report.leasing
        ? _c("partner-report-detail-info-leasing-time-line-element", {
            attrs: {
              report: _vm.report
            }
          })
        : _vm._e(),
      _c("partner-report-detail-info-body-time-line-element", {
        attrs: {
          report: _vm.report
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }