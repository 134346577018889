









































import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import DamageLocatorCombined from "@/components/damagelocator/DamageLocatorCombined.vue";
import MImageMarker from "../utility/mmmint/MImageMarker.vue";

@Component({
  components: { DamageLocatorCombined, MImageMarker }
})
export default class ReportDamageLocationTimeLineElement extends mixins(Vue) {
  @Prop()
  report!: MrfiktivReportViewModelGen;

  renderComponent = true;

  get getDamageLocatorWidth() {
    return "250px";
  }

  get damageLocation() {
    this.forceRerender();
    return this.report?.damage;
  }

  forceRerender() {
    // remove the my-component component from the DOM
    this.renderComponent = false;

    this.$nextTick(() => {
      // add my-component component in DOM
      this.renderComponent = true;
    });
  }
}
