







































import FilterCard from "@/components/filter/FilterCard.vue";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import { CancelToken } from "@/lib/utility/cancelToken";
import { simpleDate } from "@/lib/utility/date-helper";
import { getDateFilterList } from "@/lib/utility/filter";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { TimeStampEntity } from "@/models/timestampEntity";
import { MrfiktivPartnerMessageViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgPartnerMessageViewModelGen } from "@/services/thg/v1/data-contracts";
import { PartnerMessagePaginationModule } from "@/store/modules/message-pagination.store";
import { MessageModule } from "@/store/modules/message.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import TheLayoutPortalList from "../../layouts/TheLayoutPortalList.vue";
import TemplateDialog from "../template/TemplateDialog.vue";
import Tooltip from "../utility/tooltip.vue";

@Component({
  components: {
    FilterCard,
    Tooltip,
    TheLayoutPortalList,
    TemplateDialog
  }
})
export default class PartnerMessageListPaginated extends mixins(
  DarkModeHighlightMixin,
  PartnerFallbackMixin,
  PermissionMixin
) {
  loading = true;

  isLoadingMore = false;

  isLoadingDetail = false;

  statusFilter: IFilterListElement[] = [];

  dateFilter = getDateFilterList("timestamps");

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return PartnerModule.partner._id;
  }

  get filterList(): IFilterListElement[] {
    return [...this.dateFilter, ...this.statusFilter];
  }

  get paginationList() {
    return this.getModule().paginationList;
  }

  getModule() {
    return PartnerMessagePaginationModule;
  }

  async mounted() {
    this.loading = true;
    this.setIsLoadingDetail(true);
    await this.trySetByRouteOrDefault();

    if (!PartnerModule.partner._id) {
      throw new Error("No Partner Set");
    }

    // set report detail
    if (this.paginationList.length === 0) {
      // set empty message
    } else {
      try {
        await MessageModule.getMessage({ partnerId: PartnerModule.partner._id, id: this.paginationList[0].id });
      } catch (error) {
        this.$toast.error("Error Loading Message.");
      }
    }
    this.setIsLoadingDetail(false);
    this.loading = false;
  }

  async set(item: any) {
    this.setIsLoadingDetail(true);
    const message = await MessageModule.getMessage({ partnerId: PartnerModule.partner._id, id: item.id });
    if (!message) {
      this.$toast("Not found.");
      return;
    }

    if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      const partnerId = PartnerModule.partner._id;
      const messageId = item.id;

      this.$router.push({
        name: "PartnerMessagesDetail",
        params: { partnerId, messageId }
      });
    }
    this.setIsLoadingDetail(false);
  }

  async refresh() {
    this.loading = true;
    PartnerMessagePaginationModule.emptyList();

    try {
      await PartnerMessagePaginationModule.fetchAllFromBeginning({
        partnerId: PartnerModule.partner._id
      });
    } catch (error) {
      this.$log.error("Error fetching messages");
      this.$toast.error("moinmoin");
    } finally {
      this.loading = false;
    }
  }

  async loadAllFromLastId() {
    if (this.paginationList.length === 0) {
      await PartnerMessagePaginationModule.fetchAllFromBeginning({
        partnerId: PartnerModule.partner._id
      });
    } else {
      PartnerMessagePaginationModule.cancelToken?.requestCancellation();
      const cancelToken = new CancelToken();
      PartnerMessagePaginationModule.setCancelToken(cancelToken);
      await PartnerMessagePaginationModule.fetchRest({
        query: { partnerId: PartnerModule.partner._id },
        cancelToken: cancelToken
      });
    }
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      await this.loadAllFromLastId();
    } catch (e) {
      this.$toast.error((e as any).message);
    } finally {
      this.isLoadingMore = false;
    }
  }

  setIsLoadingDetail(value: boolean) {
    this.isLoadingDetail = value;
    this.$emit("isLoadingDetail", value);
  }

  /**
   * Some methods for the presentation of items in the list
   */
  itemFunctions = {
    body: (item: any) => {
      let body = item.content.body.replace("<br><br><br>", " ");
      body = item.content.body.replace("<br><br>", " ");
      body = body.replace("<br>", " ");
      return body.replace(/<[^>]+>/g, "");
    },
    date: (item: MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen) => {
      return simpleDate((item.timestamp as TimeStampEntity).created);
    }
  };
}
