var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "timeline-card",
    {
      attrs: {
        title: _vm.$t("timeLine.ActivityTimeLineItemComment.title", {
          name: _vm.name,
          date: _vm.date
        })
      },
      scopedSlots: _vm._u([
        {
          key: "menu",
          fn: function fn() {
            return [
              _vm.canUpdateActivity || _vm.deleteUpdateActivity
                ? _c(
                    "div",
                    [
                      _vm.$vuetify.breakpoint.smAndDown
                        ? _c(
                            "context-menu",
                            [
                              _c(
                                "v-list",
                                [
                                  _c("activity-time-line-item-update", {
                                    attrs: {
                                      text: true,
                                      activityLogEntry: _vm.activityLogEntry
                                    }
                                  }),
                                  _c("activity-time-line-item-delete", {
                                    attrs: {
                                      text: true,
                                      partnerId: _vm.activityLogEntry.partnerId,
                                      activityId: _vm.activityLogEntry.id
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("activity-time-line-item-update", {
                                attrs: {
                                  activityLogEntry: _vm.activityLogEntry
                                }
                              }),
                              _c("activity-time-line-item-delete", {
                                attrs: {
                                  partnerId: _vm.activityLogEntry.partnerId,
                                  activityId: _vm.activityLogEntry.id
                                }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template-editor", {
        key: _vm.activityLogEntry.comment,
        staticClass: "ma-1",
        attrs: {
          value: _vm.activityLogEntry.comment,
          readOnly: true,
          outlined: false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }