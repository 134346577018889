/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import {
  MrfiktivBookedServicesDtoGen,
  MrfiktivContactDtoGen,
  MrfiktivEmailSettingsDtoGen,
  MrfiktivOpeningHoursDtoGen,
  MrfiktivPartnerSettingsDtoGen,
  MrfiktivReportScreenDtoGen,
  MrfiktivReportSettingsDtoGen,
  MrfiktivReportWelcomeTextGen,
  MrfiktivWebsitesDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { CreateContactDto } from "./contact";

/**
 * @inheritdoc
 */
class CreateReportSettingsDto implements MrfiktivReportSettingsDtoGen {
  /**
   * Default screen order for thg
   */
  public static readonly THG_SCREEN_ORDER: ReportScreenEnum[] = [
    ReportScreenEnum.thghome,
    ReportScreenEnum.thgimpactselector,
    ReportScreenEnum.thgimpactfactor,
    ReportScreenEnum.thgregistration,
    ReportScreenEnum.thgcontact,
    ReportScreenEnum.thgpassword,
    ReportScreenEnum.thgclosing
  ];

  /**
   * Default screen order for with customer account
   */
  public static readonly THG_SCREEN_ORDER_CUSTOMER_ACCOUNT: ReportScreenEnum[] = [
    ReportScreenEnum.thghome,
    ReportScreenEnum.thgImpactSelectorWithCustomerAccount,
    ReportScreenEnum.thgimpactfactor,
    ReportScreenEnum.thgregistration,
    ReportScreenEnum.thgcontact,
    ReportScreenEnum.thgpassword,
    ReportScreenEnum.thgclosing
  ];

  /**
   * Default screen order for only customer account
   */
  public static readonly THG_SCREEN_ORDER_ONLY_CUSTOMER_ACCOUNT: ReportScreenEnum[] = [
    ReportScreenEnum.thghome,
    ReportScreenEnum.thgImpactSelectorOnlyCustomerAccount,
    ReportScreenEnum.thgregistration,
    ReportScreenEnum.thgcontact,
    ReportScreenEnum.thgpassword,
    ReportScreenEnum.thgclosing
  ];

  /**
   * @inheritdoc
   */
  logoUrl = "";

  /**
   * @inheritdoc
   */
  headerImageUrl = "";

  /**
   * @inheritdoc
   */
  headerTitle = "";

  /**
   * @inheritdoc
   */
  headerBtn = "";

  /**
   * @inheritdoc
   */
  welcomeImageUrl = "";

  /**
   * @inheritdoc
   */
  welcomeVideoUrl = "";

  /**
   * @inheritdoc
   */
  welcomeTitle = "";

  /**
   * @inheritdoc
   */
  welcomeBtn = "";

  /**
   * @inheritdoc
   */
  welcomeText: MrfiktivReportWelcomeTextGen[] = [];

  /**
   * @inheritdoc
   */
  favIconUrl = "";

  /**
   * @inheritdoc
   */
  firstColor = "";

  /**
   * @inheritdoc
   */
  secondColor = "";

  /**
   * @inheritdoc
   */
  screenOrder = [
    ReportScreenEnum.welcome,
    ReportScreenEnum.registration,
    ReportScreenEnum.damagelocator,
    ReportScreenEnum.overviewimage,
    ReportScreenEnum.componentimage,
    ReportScreenEnum.damagedetail,
    ReportScreenEnum.mileage,
    ReportScreenEnum.message,
    ReportScreenEnum.daterequest,
    ReportScreenEnum.contactdetails,
    ReportScreenEnum.closing
  ] as any[];

  /**
   * @inheritdoc
   */
  screens: MrfiktivReportScreenDtoGen[] = [];

  constructor(reportSettings?: MrfiktivReportSettingsDtoGen) {
    if (reportSettings) {
      this.logoUrl = reportSettings.logoUrl ?? "";
      this.headerImageUrl = reportSettings.headerImageUrl ?? "";
      this.headerTitle = reportSettings.headerTitle ?? "";
      this.headerBtn = reportSettings.headerBtn ?? "";
      this.welcomeImageUrl = reportSettings.welcomeImageUrl ?? "";
      this.welcomeVideoUrl = reportSettings.welcomeVideoUrl ?? "";
      this.welcomeTitle = reportSettings.welcomeTitle ?? "";
      this.welcomeText = reportSettings.welcomeText ?? [];
      this.welcomeBtn = reportSettings.welcomeBtn ?? "";
      this.favIconUrl = reportSettings.favIconUrl ?? "";
      this.firstColor = reportSettings.firstColor ?? "";
      this.secondColor = reportSettings.secondColor ?? "";
      this.screenOrder = reportSettings.screenOrder ?? [];
      this.screens = reportSettings.screens ?? [];
    }
  }
}

/**
 * @inheritdoc
 */
export class CreateEmailSettingsDto implements MrfiktivEmailSettingsDtoGen {
  /**
   * @inheritdoc
   */
  registrationShowResults = false;

  /**
   * @inheritdoc
   */
  registrationShowKsrButton = false;

  constructor(emailSettings?: MrfiktivEmailSettingsDtoGen) {
    if (emailSettings) {
      this.registrationShowResults = emailSettings.registrationShowResults ?? false;
      this.registrationShowKsrButton = emailSettings.registrationShowKsrButton ?? false;
    }
  }
}

/**
 * @inheritdoc
 */
export class CreateOpeningHoursDto implements MrfiktivOpeningHoursDtoGen {
  /**
   * @inheritdoc
   */
  monday = "";

  /**
   * @inheritdoc
   */
  tuesday = "";

  /**
   * @inheritdoc
   */
  wednesday = "";

  /**
   * @inheritdoc
   */
  thursday = "";

  /**
   * @inheritdoc
   */
  friday = "";

  /**
   * @inheritdoc
   */
  saturday = "";

  /**
   * @inheritdoc
   */
  sunday = "";

  constructor(openingHours?: MrfiktivOpeningHoursDtoGen) {
    if (openingHours) {
      this.monday = openingHours.monday;
      this.tuesday = openingHours.tuesday;
      this.wednesday = openingHours.wednesday;
      this.thursday = openingHours.thursday;
      this.friday = openingHours.friday;
      this.saturday = openingHours.saturday;
      this.sunday = openingHours.sunday;
    }
  }
}

/**
 * @inheritdoc
 */
export class CreatePartnerSettingsDto implements MrfiktivPartnerSettingsDtoGen {
  /**
   * @inheritdoc
   */
  logoUrl = "";

  /**
   * @inheritdoc
   */
  headerImageUrl = "";

  /**
   * @inheritdoc
   */
  favIconUrl? = "";

  /**
   * @inheritdoc
   */
  formTitle? = "";

  /**
   * @inheritdoc
   */
  color = "";

  /**
   * @inheritdoc
   */
  contact: MrfiktivContactDtoGen = new CreateContactDto();

  /**
   * @inheritdoc
   */
  openingHours: MrfiktivOpeningHoursDtoGen = new CreateOpeningHoursDto();

  /**
   * @inheritdoc
   */
  websites: MrfiktivWebsitesDtoGen[] = [];

  /**
   * @inheritdoc
   */
  agbUrl?: MrfiktivWebsitesDtoGen = { link: "", text: "" };

  /**
   * @inheritdoc
   */
  cardTitle? = "";

  /**
   * @inheritdoc
   */
  cardText? = "";

  /**
   * @inheritdoc
   */
  cardSubtitle? = "";

  /**
   * @inheritdoc
   */
  emailSettings: MrfiktivEmailSettingsDtoGen = new CreateEmailSettingsDto();

  /**
   * @inheritdoc
   */
  bookedServices?: MrfiktivBookedServicesDtoGen;

  /**
   * @inheritdoc
   */
  reportSettings: MrfiktivReportSettingsDtoGen = new CreateReportSettingsDto();

  /**
   * @inheritdoc
   */
  displayMap = false;

  /**
   * @inheritdoc
   */
  urls: string[] = [];

  /**
   * @inheritdoc
   */
  isDatasharingActive? = false;

  constructor(settings?: MrfiktivPartnerSettingsDtoGen) {
    if (settings) {
      this.logoUrl = settings.logoUrl;
      this.headerImageUrl = settings.headerImageUrl;
      this.favIconUrl = settings.favIconUrl;
      this.formTitle = settings.formTitle;
      this.color = settings.color;
      this.contact = new CreateContactDto(settings.contact);
      this.openingHours = new CreateOpeningHoursDto(settings.openingHours);
      this.websites = settings.websites;
      this.cardTitle = settings.cardTitle;
      this.cardText = settings.cardText;
      this.cardSubtitle = settings.cardSubtitle;
      this.emailSettings = new CreateEmailSettingsDto(settings.emailSettings);
      this.bookedServices = settings.bookedServices;
      this.reportSettings = new CreateReportSettingsDto(settings.reportSettings);
      this.displayMap = settings.displayMap;
      this.urls = settings.urls;
      this.agbUrl = settings.agbUrl;
      this.isDatasharingActive = settings.isDatasharingActive;
    }
  }
}
