var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "pt-3 pb-3"
    },
    _vm._l(_vm.requirementList, function(requirement) {
      return _c(
        "div",
        {
          key: requirement.text
        },
        [
          requirement.test
            ? _c(
                "div",
                {
                  staticClass: "satisfiedRequirement"
                },
                [_vm._v(_vm._s(_vm.working) + " " + _vm._s(requirement.text))]
              )
            : _vm._e(),
          !requirement.test
            ? _c(
                "div",
                {
                  staticClass: "unsatisfiedRequirement"
                },
                [_vm._v(_vm._s(_vm.fail) + " " + _vm._s(requirement.text))]
              )
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }