var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      model: {
        value: _vm.isValid,
        callback: function callback($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    [
      !_vm.hideTitle
        ? _c(
            "v-row",
            {
              staticClass: "mt-1",
              attrs: {
                "no-gutters": ""
              }
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      required: "",
                      readonly: _vm.disabled,
                      label: _vm.$t("project.ticket.title"),
                      rules: _vm.titleRule
                    },
                    model: {
                      value: _vm.ticket.title,
                      callback: function callback($$v) {
                        _vm.$set(_vm.ticket, "title", $$v)
                      },
                      expression: "ticket.title"
                    }
                  })
                ],
                1
              ),
              _vm.suggestions && _vm.suggestions.length
                ? _c(
                    "v-col",
                    {
                      staticClass: "mt-n7",
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("a-eicon"),
                      _vm._l(_vm.suggestions, function(suggestion, index) {
                        return _c(
                          "v-btn",
                          {
                            key: suggestion + index,
                            attrs: {
                              text: "",
                              small: ""
                            },
                            on: {
                              click: function click($event) {
                                _vm.ticket.title = ""
                                  .concat(_vm.ticket.title, " ")
                                  .concat(suggestion)
                              }
                            }
                          },
                          [
                            _c("i", [
                              _c("small", {
                                domProps: {
                                  textContent: _vm._s(suggestion)
                                }
                              })
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      !_vm.hideBody
        ? _c(
            "div",
            {
              staticClass: "mb-12",
              staticStyle: {
                height: "200px"
              }
            },
            [
              _c("template-editor", {
                attrs: {
                  readOnly: _vm.disabled,
                  label: _vm.$t("project.ticket.description")
                },
                model: {
                  value: _vm.ticket.body,
                  callback: function callback($$v) {
                    _vm.$set(_vm.ticket, "body", $$v)
                  },
                  expression: "ticket.body"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.hideDue
        ? _c(
            "v-row",
            {
              staticClass: "pt-4"
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("v-text-field", {
                    key: "ticket.dueDate" + _vm.forceDateUpdateKey,
                    attrs: {
                      type: "date",
                      readonly: _vm.disabled,
                      label: _vm.$t("project.ticket.due"),
                      outlined: "",
                      clearable: !_vm.disabled
                    },
                    on: {
                      change: _vm.change
                    },
                    model: {
                      value: _vm.ticket.dueDate,
                      callback: function callback($$v) {
                        _vm.$set(_vm.ticket, "dueDate", $$v)
                      },
                      expression: "ticket.dueDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.hideAssignees
        ? _c("v-select", {
            attrs: {
              readonly: _vm.disabled,
              "item-value": "id",
              "item-text": "firstName",
              label: _vm.$t("project.ticket.assignees"),
              items: _vm.users,
              multiple: "",
              outlined: "",
              clearable: ""
            },
            on: {
              change: _vm.change
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _c(
                        "v-chip",
                        _vm._b(
                          {
                            attrs: {
                              outlined: ""
                            }
                          },
                          "v-chip",
                          item.attrs,
                          false
                        ),
                        [
                          item.firstName && item.lastName
                            ? _c(
                                "v-avatar",
                                {
                                  staticClass: "white--text",
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    left: ""
                                  }
                                },
                                [
                                  _c("small", [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.firstName[0]) +
                                        _vm._s(item.lastName[0])
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c("small", [
                            _vm._v(
                              _vm._s(item.firstName) +
                                " " +
                                _vm._s(item.lastName)
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item",
                  fn: function fn(_ref2) {
                    var item = _ref2.item
                    return [
                      _c(
                        "v-list-item-avatar",
                        [
                          item.firstName && item.lastName
                            ? _c(
                                "v-avatar",
                                {
                                  staticClass: "white--text",
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    size: "35px",
                                    right: true
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.firstName[0]) +
                                      _vm._s(item.lastName[0]) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(item.firstName) +
                                " " +
                                _vm._s(item.lastName) +
                                " "
                            )
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(" " + _vm._s(item.userName) + " ")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3231599243
            ),
            model: {
              value: _vm.assignees,
              callback: function callback($$v) {
                _vm.assignees = $$v
              },
              expression: "assignees"
            }
          })
        : _vm._e(),
      !_vm.hideTags
        ? _c("v-combobox", {
            attrs: {
              readonly: _vm.disabled,
              items: _vm.tags,
              label: _vm.$t("project.ticket.tags"),
              multiple: "",
              outlined: "",
              clearable: ""
            },
            on: {
              change: _vm.change
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function fn(_ref3) {
                    var item = _ref3.item
                    return [
                      _c(
                        "v-chip",
                        _vm._b(
                          {
                            attrs: {
                              outlined: ""
                            }
                          },
                          "v-chip",
                          item.attrs,
                          false
                        ),
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    ]
                  }
                },
                {
                  key: "item",
                  fn: function fn(_ref4) {
                    var item = _ref4.item
                    return [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(item) + " ")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1686412609
            ),
            model: {
              value: _vm.ticket.tags,
              callback: function callback($$v) {
                _vm.$set(_vm.ticket, "tags", $$v)
              },
              expression: "ticket.tags"
            }
          })
        : _vm._e(),
      !_vm.hideProject
        ? _c("v-select", {
            attrs: {
              loading: _vm.isLoadingProjects,
              readonly: _vm.disabled,
              "item-value": "id",
              "item-name": "title",
              label: _vm.$t("project.ticket.project"),
              items: _vm.projects,
              outlined: "",
              clearable: ""
            },
            on: {
              change: _vm.change
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function fn() {
                    return [
                      _c("project-create-dialog", {
                        on: {
                          created: function created(project) {
                            return (_vm.ticket.projectId = project.id)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function fn(_ref5) {
                                var on = _ref5.on
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: {
                                        position: "relative",
                                        top: "-6px"
                                      },
                                      attrs: {
                                        icon: "",
                                        loading: _vm.isLoadingProjects
                                      },
                                      on: {
                                        click: function click($event) {
                                          $event.stopPropagation()
                                          return on.apply(null, arguments)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          8061768
                        )
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "selection",
                  fn: function fn(_ref6) {
                    var item = _ref6.item
                    return [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(item.title) + " ")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item",
                  fn: function fn(_ref7) {
                    var item = _ref7.item
                    return [
                      _c(
                        "v-list-item-content",
                        {
                          staticStyle: {
                            "max-width": "400px"
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(item.title) + " ")
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(" " + _vm._s(item.description) + " ")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              2743110993
            ),
            model: {
              value: _vm.ticket.projectId,
              callback: function callback($$v) {
                _vm.$set(_vm.ticket, "projectId", $$v)
              },
              expression: "ticket.projectId"
            }
          })
        : _vm._e(),
      !_vm.hideProject
        ? _c(
            "div",
            {
              staticClass: "mt-n7",
              staticStyle: {
                display: "flex"
              }
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    "x-small": "",
                    text: "",
                    elevation: 0,
                    loading: _vm.isLoadingProjects
                  },
                  on: {
                    click: _vm.refreshProjects
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.verbs.refresh")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    "x-small": "",
                    text: "",
                    elevation: 0
                  },
                  on: {
                    click: _vm.goToProject
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.verbs.view")) + " ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }