/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { SharedContent } from "../v1/SharedContent";
import {
  MrfiktivCreateSharedContentElementDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivSharedContentAccessViewmodelGen,
  MrfiktivSharedContentControllerGetAllForPartnerParamsGen,
  MrfiktivSharedContentControllerUpdatePayloadGen,
  MrfiktivSharedContentViewmodelGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * Service to talk to mrfiktiv shared content endpoint.
 */
export class SharedContentService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: SharedContent;

  /**
   * @class Initialize SharedContentService
   */
  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new SharedContent(this.client);
  }

  /**
   *
   * Creates shared content
   *
   * @param parnterId
   * @param dto
   * @returns
   */
  async create(
    parnterId: string,
    dto: MrfiktivCreateSharedContentElementDtoGen
  ): Promise<MrfiktivSharedContentViewmodelGen> {
    const response = await this.proxy.sharedContentControllerCreate(parnterId, dto);
    return response.data;
  }

  /**
   *
   * Updates shared content
   *
   * @param parnterId
   * @param sharedContentId
   * @param dto
   * @returns
   */
  async update(
    parnterId: string,
    sharedContentId: string,
    dto: MrfiktivSharedContentControllerUpdatePayloadGen
  ): Promise<MrfiktivSharedContentViewmodelGen> {
    const response = await this.proxy.sharedContentControllerUpdate(parnterId, sharedContentId, dto);
    return response.data;
  }

  /**
   *
   * Deletes shared content
   *
   * @param parnterId
   * @param sharedContentId
   * @param dto
   * @returns
   */
  async delete(parnterId: string, sharedContentId: string): Promise<MrfiktivSharedContentViewmodelGen> {
    const response = await this.proxy.sharedContentControllerDelete(parnterId, sharedContentId);
    return response.data;
  }

  /**
   *
   * Get shared content
   *
   * @param parnterId
   * @param sharedContentId
   * @param dto
   * @returns
   */
  async get(parnterId: string, sharedContentId: string): Promise<MrfiktivSharedContentViewmodelGen> {
    const response = await this.proxy.sharedContentControllerFind(parnterId, sharedContentId);
    return response.data;
  }

  /**
   * Geths the report documents as pages
   *
   * @param query
   * @returns
   */
  async getSharedContentPaginated(
    query: MrfiktivSharedContentControllerGetAllForPartnerParamsGen
  ): Promise<MrfiktivPageViewModelGen & { data?: MrfiktivSharedContentViewmodelGen[] }> {
    return (await this.proxy.sharedContentControllerGetAllForPartner(query)).data;
  }

  /**
   * Gets the documents that are shared
   * @param partnerId
   * @param sharedContentId
   * @param accessToken
   * @returns
   */
  async sharedContentReportControllerAccessForPartner(
    partnerId: string,
    sharedContentId: string,
    accessToken: string
  ): Promise<MrfiktivSharedContentAccessViewmodelGen> {
    return (await this.proxy.sharedContentAccessControllerAccessForPartner(partnerId, sharedContentId, accessToken))
      .data;
  }
}

export default new SharedContentService(new MrfiktivHttpClientProvider());
