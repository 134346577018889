
























import PartnerMessageListPaginated from "@/components/partner/PartnerMessagePaginatedList.vue";
import PartnerReportDetail from "@/components/partner/PartnerReportDetail.vue";
import PartnerReportList from "@/components/partner/PartnerReportList.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";
import { PartnerMessagePaginationModule } from "@/store/modules/message-pagination.store";
import { PartnerModule } from "@/store/modules/partner";
import { MessageModule } from "@/store/modules/message.store";
import PartnerMessageDetail from "@/components/partner/PartnerMessageDetail.vue";

@Component({
  components: {
    TheLayoutPortalSplit,
    PartnerReportList,
    PartnerReportDetail,
    PartnerMessageListPaginated,
    PartnerMessageDetail
  }
})
export default class PartnerMessages extends PartnerFallbackMixin {
  isDetailLoading = false;

  loading = true;

  setIsLoadingDetail(value: boolean) {
    this.isDetailLoading = value;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
    await this.load();
  }

  async refresh() {
    await this.load();
  }

  get message() {
    return MessageModule.message;
  }

  async load() {
    this.loading = true;
    this.isDetailLoading = true;

    // no THGs loaded? Fetch!
    try {
      await PartnerMessagePaginationModule.fetchAllFromBeginning({
        partnerId: PartnerModule.partner._id
      });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false;
    }
  }
}
