







































































































































import { autoCompleteCountryCodes } from "@/lib/CountryCodeHelper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivCreatePartnerDtoGen, MrfiktivPartnerViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { CreatePartnerDto } from "@/store/models/createPartnerDto";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class PartnerForm extends DarkModeHighlightMixin {
  @Prop()
  partner!: MrfiktivPartnerViewModelGen;

  @Prop({ default: false })
  isLoading!: boolean;

  partnerTypes = Object.keys(PartnerTypeEnum).map(k => PartnerTypeEnum[k as any]);
  countryCodes = autoCompleteCountryCodes;

  disabled = true;

  valid = false;

  update = new CreatePartnerDto(this.partner);

  save(): MrfiktivCreatePartnerDtoGen {
    this.$emit("save", this.update);
    this.disabled = true;

    return this.update;
  }

  abort() {
    this.disabled = true;
  }
}
