






























import PartnerCreateRequirementsList from "@/components/partner/PartnerCreateRequirementsList.vue";
import PartnerDetailImage from "@/components/partner/PartnerDetailImage.vue";
import { Type } from "@/lib/utility/type";
import { PartnerUpdateEntity } from "@/models/partnerUpdateEntity";
import { MrfiktivCreatePartnerDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    PartnerDetailImage,
    PartnerCreateRequirementsList
  }
})
export default class PartnerCreateOrUpdateSubmitCard extends Vue {
  @Prop()
  partnerDto!: MrfiktivCreatePartnerDtoGen | PartnerUpdateEntity | any;

  @Prop({ default: false })
  isUpdatePartner!: boolean;

  isLoading = false;

  async createPartner() {
    this.isLoading = true;

    const createdPartner = await PartnerModule.createPartner(this.partnerDto as MrfiktivCreatePartnerDtoGen);
    if (!createdPartner) {
      Vue.$toast.error("Fehler beim Anlegen des Partners");
    } else {
      Vue.$toast.success("Partner wurde erfolgreich angelegt.");
      this.$router.push({ name: "PartnerDetailView", params: { partnerId: createdPartner._id } });
      this.$emit("closeDialog", true);
    }

    this.isLoading = false;
  }

  async updatePartner() {
    this.isLoading = true;
    const updatePartner = await PartnerModule.updatePartner({
      id: PartnerModule.partner._id,
      partnerUpdateEntity: this.partnerDto as PartnerUpdateEntity
    });

    if (!updatePartner) {
      Vue.$toast.error("Fehler beim ändern des Partners");
    } else {
      Vue.$toast.success("Partner erfolgreich geändert.");
      this.$router.push({ name: "PartnerDetailView", params: { partnerId: updatePartner._id } });
    }
    this.$emit("closeDialog", true);
    this.isLoading = false;
  }

  get isValidCreate() {
    return (
      this.isValidGeneralInformations() &&
      this.isValidCompanyUserName() &&
      this.isValidOpeningHours() &&
      this.isValidLandingpageSettings() &&
      this.isValidDomains()
    );
  }

  get isValidUpdate() {
    return this.isValidGeneralInformations() && this.isValidLandingpageSettings() && this.isValidDomains();
  }

  get requirementList() {
    const reqs = [
      {
        test: this.isValidGeneralInformations(),
        text: "Generelle Informationen."
      },
      {
        test: this.isValidLandingpageSettings(),
        text: "Landingpage Einstellungen."
      },
      {
        test: this.isValidDomains(),
        text: "Domains mit DNS."
      }
    ];
    return reqs;
  }

  isValidDomains() {
    return this.partnerDto.settings.urls.length > 0;
  }

  isValidGeneralInformations() {
    return (
      !Type.isBlankString(this.partnerDto.companyName) &&
      !Type.isBlankString(this.partnerDto.partnerType) &&
      !Type.isBlankString(this.partnerDto.contact.email) &&
      !Type.isBlankString(this.partnerDto.contact.phone) &&
      !Type.isBlankString(this.partnerDto.address.street) &&
      !Type.isBlankString(this.partnerDto.address.zip) &&
      !Type.isBlankString(this.partnerDto.address.city) &&
      !Type.isBlankString(this.partnerDto.address.state) &&
      !(this.partnerDto.address.geo.lat === 0) &&
      !(this.partnerDto.address.geo.lng === 0)
    );
  }

  isValidCompanyUserName() {
    return !Type.isBlankString(this.partnerDto.companyUsername);
  }

  isValidOpeningHours() {
    return (
      !Type.isBlankString(this.partnerDto.settings.openingHours.monday) &&
      !Type.isBlankString(this.partnerDto.settings.openingHours.tuesday) &&
      !Type.isBlankString(this.partnerDto.settings.openingHours.wednesday) &&
      !Type.isBlankString(this.partnerDto.settings.openingHours.thursday) &&
      !Type.isBlankString(this.partnerDto.settings.openingHours.friday) &&
      !Type.isBlankString(this.partnerDto.settings.openingHours.saturday) &&
      !Type.isBlankString(this.partnerDto.settings.openingHours.sunday)
    );
  }

  isValidLandingpageSettings() {
    return (
      !Type.isBlankString(this.partnerDto.settings.color) &&
      !Type.isBlankString(this.partnerDto.settings.contact.email) &&
      !Type.isBlankString(this.partnerDto.settings.contact.phone) &&
      !Type.isBlankString(this.partnerDto.settings.logoUrl) &&
      !Type.isBlankString(this.partnerDto.settings.headerImageUrl) &&
      !Type.isBlankString(this.partnerDto.settings.websites[0].link) &&
      !Type.isBlankString(this.partnerDto.settings.websites[0].text)
    );
  }
}
