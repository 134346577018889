






















































































































































import PartnerDetailImage from "@/components/partner/PartnerDetailImage.vue";
import PublicImageUploadDialog from "@/components/public-image/PublicImageUploadDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { SettingsEntity } from "@/models/settingsEntity";
import { PublicImageImageViewmodelGen } from "@/services/image/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PublicImageFolderEnum } from "@/store/enum/public-image/publicImageFolderEnum";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";

@Component({
  components: {
    PartnerDetailImage,
    PublicImageUploadDialog
  }
})
export default class PartnerCreateOrUpdateLandingpageSettigns extends Vue {
  @Prop()
  settings!: SettingsEntity;

  @Prop()
  disabled!: boolean;

  get rules() {
    return [requiredRule()];
  }

  get folderName() {
    return ConfigModule.appContext === AppContextEnum.THG_PORTAL
      ? PublicImageFolderEnum.THG
      : PublicImageFolderEnum.PARTNER;
  }

  handleLogo(image: PublicImageImageViewmodelGen) {
    if (image && image.cdnUrl) {
      this.settings.logoUrl = image.cdnUrl;
    }
  }

  handleHeader(image: PublicImageImageViewmodelGen) {
    if (image && image.cdnUrl) {
      this.settings.headerImageUrl = image.cdnUrl;
    }
  }

  handleFavIcon(image: PublicImageImageViewmodelGen) {
    if (image && image.cdnUrl) {
      this.settings.favIconUrl = image.cdnUrl;
    }
  }
}
