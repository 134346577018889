import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module } from "vuex-module-decorators";
import { PaginationFilterListElement } from "./base-pagination.store";
import { ReportPaginationModule } from "./report-pagination.store";

import { IEventUIDto, EventUIDto } from "@/lib/dto/event/event-ui.dto";
import { IReport } from "@/models/report.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { ITicket, Ticket } from "@/models/ticket.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";

import { EventPageDataProvider } from "./page-data-provider/event.page-data-provider";
import { ReportPageDataProvider } from "./page-data-provider/report.page-data-provider";
import { SignDocumentPageDataProvider } from "./page-data-provider/sign-document.page-data-provider";
import { TicketPageDataProvider } from "./page-data-provider/ticket.page-data-provider";
import { VehiclePageDataProvider } from "./page-data-provider/vehicle.page-data-provider";

import { EventDataAccessLayer } from "./access-layers/event.access-layer";
import { ReportDataAccessLayer } from "./access-layers/report.access-layer";
import { SignDocumentDataAccessLayer } from "./access-layers/sign-document.access-layer";
import { TicketDataAccessLayer } from "./access-layers/ticket.access-layer";
import { VehicleAccessLayer } from "./access-layers/vehicle.access-layer";

import {
  MrfiktivEventControllerGetParamsGen,
  MrfiktivReportControllerFindAllParamsGen,
  MrfiktivTicketControllerGetParamsGen,
  MrfiktivVehicleControllerGetAllParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { SignDocumentControllerFindAllParamsGen } from "@/services/sign/v1/data-contracts";

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-vehicle",
  store
})
class RefsVehicleStore extends PaginatedBaseStore<IVehicle, MrfiktivVehicleControllerGetAllParamsGen> {
  _data = VehicleAccessLayer;
  _pageProvider = VehiclePageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);
  filterOptions: PaginationFilterListElement[] = Vehicle.filterables;
}

/**
 * wraps the timed event endpoint into a makeshift event pagination @see RefTypeMap
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-event",
  store
})
class RefsEventStore extends PaginatedBaseStore<IEventUIDto, MrfiktivEventControllerGetParamsGen> {
  protected _data = EventDataAccessLayer;
  protected _pageProvider = EventPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = EventUIDto.filterables;

  @Action
  addToList(document: IEventUIDto): void {
    this._data.set(document);
  }
}

/**
 * wraps the timed event endpoint into a makeshift event pagination @see RefTypeMap
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-report",
  store
})
class RefsReportStore extends PaginatedBaseStore<IReport, MrfiktivReportControllerFindAllParamsGen> {
  protected _data = ReportDataAccessLayer;
  protected _pageProvider = ReportPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = ReportPaginationModule.filterOptions;

  @Action
  addToList(document: IReport): void {
    this._data.set(document);
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-ticket",
  store
})
export class RefsTicketStore extends PaginatedBaseStore<ITicket, MrfiktivTicketControllerGetParamsGen> {
  _data = TicketDataAccessLayer;
  _pageProvider = TicketPageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Ticket.filterables;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "refs-sign-document",
  store
})
export class RefsSignDocumentStore extends PaginatedBaseStore<ISignDocument, SignDocumentControllerFindAllParamsGen> {
  _data = SignDocumentDataAccessLayer;
  _pageProvider = SignDocumentPageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = SignDocument.filterables;
}

export const RefsVehicleModule = getModule(RefsVehicleStore);
export const RefsEventModule = getModule(RefsEventStore);
export const RefsReportModule = getModule(RefsReportStore);
export const RefsTicketModule = getModule(RefsTicketStore);
export const RefsSignDocumentModule = getModule(RefsSignDocumentStore);

/**
 * Maps reference types to their respective modules and icons
 */
export const RefTypeMap: Map<BackendResourceEnum, { module: PaginatedBaseStore<any, any>; icon: string }> = (() => {
  const map = new Map();
  map.set(BackendResourceEnum.VEHICLE, { module: RefsVehicleModule, icon: "mdi-car" });
  map.set(BackendResourceEnum.REPORT, {
    module: RefsReportModule,
    icon: "mdi-wrench-outline"
  });
  map.set(BackendResourceEnum.EVENT, { module: RefsEventModule, icon: "mdi-calendar" });
  map.set(BackendResourceEnum.TICKET, {
    module: RefsTicketModule,
    icon: "mdi-ticket-outline"
  });
  map.set(BackendResourceEnum.DOCUMENT, {
    module: RefsSignDocumentModule,
    icon: "mdi-file-multiple-outline"
  });
  map.set("", { module: undefined, icon: "mdi-progress-question" });

  return map;
})();
