


















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TimelineCard from "../utility/TimelineItem.vue";
import PartnerReportUpdateReportDialog, { ReportUpdateTabEnum } from "./PartnerReportUpdateReportDialog.vue";

@Component({
  components: {
    TimelineCard,
    PartnerReportUpdateReportDialog
  }
})
export default class PartnerReportDetailInfoBodyTimeLineElement extends mixins(
  DarkModeHighlightMixin,
  PermissionMixin
) {
  @Prop()
  report!: MrfiktivReportViewModelGen;

  search = "";

  get ReportUpdateTabEnum() {
    return ReportUpdateTabEnum;
  }

  get isBody() {
    if (this.report.body) {
      if (this.report.body.length) {
        return true;
      }
    }
    return false;
  }
}
