var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    {
      attrs: {
        fluid: ""
      }
    },
    [
      _c(
        "v-list",
        {
          attrs: {
            dense: ""
          }
        },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    top: "",
                    "nudge-right": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function fn(_ref) {
                        var on = _ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  icon: "",
                                  large: ""
                                },
                                on: {
                                  click: _vm.sendMail
                                }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-email-outline")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("navigation.TheSettingsList.sendMail"))
                    )
                  ])
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: {
                    top: "",
                    "nudge-right": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function fn(_ref2) {
                        var on = _ref2.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  icon: "",
                                  large: ""
                                },
                                on: {
                                  click: _vm.toggle
                                }
                              },
                              on
                            ),
                            [
                              !_vm.darkMode
                                ? _c("v-icon", [_vm._v("mdi-weather-night")])
                                : _vm._e(),
                              _vm.darkMode
                                ? _c("v-icon", [_vm._v("mdi-weather-sunny")])
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("navigation.TheSettingsList.toggleDarkMode")
                      )
                    )
                  ])
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: {
                    top: "",
                    "nudge-right": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function fn(_ref3) {
                        var on = _ref3.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  icon: "",
                                  large: ""
                                },
                                on: {
                                  click: _vm.changeLanguage
                                }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-web")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("navigation.TheSettingsList.toggleLanguage")
                      )
                    )
                  ])
                ]
              ),
              _vm.isMintFuture
                ? _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        "nudge-right": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function fn(_ref4) {
                              var on = _ref4.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        large: ""
                                      },
                                      on: {
                                        click: _vm.submitRating
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-star-plus-outline")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3467107700
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("navigation.TheSettingsList.rate"))
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }