



























































import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { PartnerUpdateEntity } from "@/models/partnerUpdateEntity";
import { CreatePartnerDto, UpdatePartnerDto } from "@/store/models/createPartnerDto";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class PartnerBatchUpdateDialog extends DarkModeHighlightMixin {
  @Prop({ default: [] })
  partners!: PartnerEntity[];

  updateablePartner: PartnerUpdateEntity[] = [];

  dialog = false;
  isValid = false;
  isLoading = false;

  cardTitle = 0;
  cardSubtitle = "";

  averageUpdateInMs = 120;
  sleepInMs = 50;

  initialize() {
    for (const partner of this.partners) {
      const dto = new CreatePartnerDto(partner);
      this.updateablePartner.push(dto);
    }

    this.cardTitle = parseInt(this.partners[0].settings?.cardTitle || "0");
    this.cardSubtitle = this.partners[0].settings?.cardSubtitle || "mindestens";
  }

  closeDialog() {
    this.dialog = false;
  }

  get requiredRule() {
    return requiredRule();
  }

  get companyNames() {
    return this.partners.map(p => p.companyName);
  }

  async save() {
    const failed: PartnerEntity[] = [];

    for (const partner of this.partners) {
      const dto = new UpdatePartnerDto(partner);
      dto.settings.cardTitle = `${this.cardTitle}`;
      dto.settings.cardSubtitle = this.cardSubtitle;

      this.isLoading = true;

      try {
        const sleepInMs = this.sleepInMs;

        await new Promise(resolve => setTimeout(resolve, sleepInMs));

        const updatePartner = await PartnerModule.updatePartner({
          id: partner._id,
          partnerUpdateEntity: dto
        });

        if (!updatePartner) {
          this.$toast.error(`Fehler beim ändern des Partners ${partner._id}`);
          failed.push(partner);
        }
      } catch (error) {
        failed.push(partner);
        this.$log.error(error);
        this.$toast.error(`Fehler beim ändern des Partners ${partner._id}`);
      }
    }

    if (failed.length > 0) {
      this.$toast.error(`Fehler beim Ändern von ${failed.length} Partner`);
      this.$emit("failed", failed);
    } else {
      this.$toast.success(`${this.partners.length} updated`);
    }

    this.isLoading = false;
    this.closeDialog();
  }
}
