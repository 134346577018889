
























































































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import PartnerRegistrationCard from "@/components/partner/PartnerRegistrationCard.vue";
import PartnerReportDetailImageCard from "@/components/partner/PartnerReportDetailImageCard.vue";
import PartnerReportDetailImageTimeLineElement from "@/components/partner/PartnerReportDetailImageTimeLineElement.vue";
import PartnerReportDetailInfoDateCard from "@/components/partner/PartnerReportDetailInfoDateCard.vue";
import PartnerReportDetailInfoMessageCard from "@/components/partner/PartnerReportDetailInfoMessageCard.vue";
import PartnerReportDetailMetaCard from "@/components/partner/PartnerReportDetailMetaCard.vue";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { detailedDate, simpleDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { RegistrationResultsEntity } from "@/models/registrationResultsEntity";
import { IVehicle } from "@/models/vehicle.entity";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsVehicle from "../utility/RefsVehicle.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLine from "./ActivityTimeLine.vue";
import CustomerContactTimeLineElement from "./CustomerContactTimeLineElement.vue";
import PartnerAccidentDetailsTimeLineElement from "./PartnerAccidentDetailsTimeLineElement.vue";
import PartnerInsuranceDetailsTimeLineElement from "./PartnerInsuranceDetailsTimeLineElement.vue";
import PartnerRegistrationTimeLineElement from "./PartnerRegistrationTimeLineElement.vue";
import PartnerReportDetailInfoBodyTimeLineElement from "./PartnerReportDetailInfoBodyTimeLineElement.vue";
import PartnerReportDetailInfoLeasingTimeLineElement from "./PartnerReportDetailInfoLeasingTimeLineElement.vue";
import PartnerReportDetailInfoWitnessTimeLineElement from "./PartnerReportDetailInfoWitnessTimeLineElement.vue";
import PartnerReportDetailMetaTimeLineElement from "./PartnerReportDetailMetaTimeLineElement.vue";
import SendToDaDialog from "./PartnerReportDetailSendToDaDialog.vue";
import SendToExternOption from "./PartnerReportDetailSendToExternOption.vue";
import SendToKsrDialog from "./PartnerReportDetailSendToKsrDialog.vue";
import SendToPdrDialog from "./PartnerReportDetailSendToPdrDialog.vue";
import PartnerReportUpdateReportDialog, { ReportUpdateTabEnum } from "./PartnerReportUpdateReportDialog.vue";
import ReportActivityBoxCommentComponent from "./ReportActivityBoxCommentComponent.vue";
import ReportActivityBoxMailComponent from "./ReportActivityBoxMailComponent.vue";
import ReportActivityBoxShareComponent from "./ReportActivityBoxShareComponent.vue";
import ReportPoliceDetailsTimeLineElement from "./ReportPoliceDetailsTimeLineElement.vue";
import ReportPreExistingDamageDetailsTimeLineElement from "./ReportPreExistingDamageDetailsTimeLineElement.vue";
import VehicleTimeLineElement from "./VehicleTimeLineElement.vue";
import ReportDamageLocationTimeLineElement from "./ReportDamageLocationTimeLineElement.vue";
import HandoverTimeLineElement from "./HandoverTimeLineElement.vue";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    CustomerContactCard,
    PartnerReportDetailInfoMessageCard,
    PartnerReportDetailInfoDateCard,
    PartnerRegistrationCard,
    PartnerReportDetailImageCard,
    ReportActivityBoxShareComponent,
    PartnerReportDetailMetaCard,
    PartnerReportDetailInfoBodyTimeLineElement,
    PartnerReportDetailImageTimeLineElement,
    CustomerContactTimeLineElement,
    PartnerReportDetailInfoWitnessTimeLineElement,
    PartnerReportDetailMetaTimeLineElement,
    PartnerRegistrationTimeLineElement,
    SendToExternOption,
    SendToKsrDialog,
    SendToDaDialog,
    SendToPdrDialog,
    ActivityTimeLine,
    ReportActivityBoxCommentComponent,
    ReportActivityBoxMailComponent,
    TimelineCard,
    PartnerAccidentDetailsTimeLineElement,
    ReportPoliceDetailsTimeLineElement,
    PartnerReportUpdateReportDialog,
    PartnerReportDetailInfoLeasingTimeLineElement,
    ReportPreExistingDamageDetailsTimeLineElement,
    PartnerInsuranceDetailsTimeLineElement,
    ReportDamageLocationTimeLineElement,
    HandoverTimeLineElement,
    VehicleTimeLineElement,
    RefsVehicle,
    ConfirmActionDialog
  }
})
export default class ReportBaseTimeLine extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop()
  report!: MrfiktivReportViewModelGen;

  registrationUpdateLoading = false;
  tab = 0;
  digitalSignatureRequest = false;
  selected = "";
  isDialogActive = false;

  sheet = false;

  get ReportUpdateTabEnum() {
    return ReportUpdateTabEnum;
  }

  get date() {
    if (this.report?.timestamps?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.report?.timestamps?.created, locale);
    }
    return "Kein Datum";
  }

  get partner() {
    return PartnerModule.partner;
  }

  get getImages() {
    return this.report?.images;
  }

  get witness() {
    return this.report?.witness || [];
  }

  get customerNameInitial() {
    return this.report.customerName ? this.report.customerName[0] : "";
  }

  /**
   * Open the dialog to send to ksr or da
   */
  openSendDialog(dialog: string) {
    this.isDialogActive = true;
    this.selected = dialog;
  }

  showExportDialog() {
    this.$emit("showExportDialog");
  }

  showCommentDialog() {
    this.sheet = true;
    this.$emit("showCommentDialog");
  }

  showEmailDialog() {
    this.$emit("showEmailDialog");
  }

  showSignDialog() {
    this.$emit("showSignDialog");
  }

  async updateRegistration(registration: RegistrationResultsEntity) {
    this.registrationUpdateLoading = true;
    if (!registration) {
      this.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    const partnerEntity = await PartnerModule.report.addRegistrationResults(registration);

    if (!partnerEntity) {
      this.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    this.registrationUpdateLoading = false;
  }

  get datePreference() {
    return simpleDate(this.report.datePreference);
  }

  openVehicleDetail(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, VehicleTabs.HOME);
  }

  thumbs(up: boolean) {
    this.$emit("thumbs", up);
  }
}
