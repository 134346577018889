










import PartnerMessageDetail from "@/components/partner/PartnerMessageDetail.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MessageModule } from "@/store/modules/message.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    PartnerMessageDetail
  }
})
export default class PartnerMessagesDetail extends PartnerFallbackMixin {
  isDetailLoading = false;

  loading = true;
  isGoHomeOnBack = false;

  setIsLoadingDetail(value: boolean) {
    this.isDetailLoading = value;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
    await this.load();
  }

  async refresh() {
    await this.load();
  }

  get message() {
    return MessageModule.message;
  }

  async load() {
    this.loading = true;
    this.isDetailLoading = true;
    try {
      await MessageModule.getMessage({ partnerId: PartnerModule.partner._id, id: this.$route.params.messageId });
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Error loading Message");
      this.$router.push({ name: "PartnerMessages" });
    }
    this.loading = false;
    this.isDetailLoading = false;
  }
}
