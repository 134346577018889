



































































































































































import { handleError } from "@/lib/utility/handleError";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { ITicket } from "@/models/ticket.entity";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ProjectModule } from "@/store/modules/project.store";
import { TicketModule } from "@/store/modules/ticket.store";
import { ProjectGoToHelper } from "@/views/project/mixins/ProjectGoToMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import CustomFieldListForm from "../report/CustomFieldListForm.vue";
import TemplateEditor from "../template/TemplateEditor.vue";
import AEicon from "../utility/AEicon.vue";
import Tooltip from "../utility/tooltip.vue";
import AssigneeUpdateMixin, { IAssigneeUpdateMixin } from "./AssigneeUpdateMixin.vue";
import ProjectCreateDialog from "./ProjectCreateDialog.vue";

@Component({
  components: { TemplateEditor, CustomFieldListForm, Tooltip, AEicon, ProjectCreateDialog }
})
export default class TicketForm extends mixins(RulesMixin, AssigneeUpdateMixin) implements IAssigneeUpdateMixin {
  @Prop()
  private value!: ITicket;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  hideTitle!: boolean;

  @Prop({ default: false })
  hideBody!: boolean;

  @Prop({ default: false })
  hideAssignees!: boolean;

  @Prop({ default: false })
  hideDue!: boolean;

  @Prop({ default: false })
  hideTags!: boolean;

  @Prop({ default: false })
  hideProject!: boolean;

  @Prop()
  private valid!: boolean;

  @Prop()
  private titleSuggestions?: string[];

  forceDateUpdateKey = 0;

  isLoadingProjects = false;

  get suggestions() {
    const suggestions: string[] = [];

    for (const suggestion of this.titleSuggestions ?? []) {
      if (!this.ticket.title.includes(suggestion) && suggestions.length < 3) {
        suggestions.push(suggestion);
      }
    }

    return suggestions;
  }

  /**
   * Returns required rule
   * or a silent required rule, if suggestions are given (to prevent overlap of error message with suggestions)
   */
  get titleRule() {
    if (this.titleSuggestions?.length) {
      return this.requiredRuleSilent;
    }

    return this.requiredRule;
  }

  get ticket() {
    return this.value;
  }

  set ticket(ticket: ITicket) {
    this.$emit("input", ticket);
  }

  get projects() {
    return ProjectModule.paginationList;
  }

  get users() {
    return PartnerUserModule.paginationList;
  }

  get isValid() {
    return this.valid;
  }

  set isValid(v: boolean) {
    this.$emit("update:valid", v);
  }

  get tags() {
    const tags = new Set<string>();
    TicketModule.entities.forEach(t => t.tags?.forEach(tag => tags.add(tag)));

    return Array.from(tags).sort();
  }

  get assignees() {
    return this.ticket.assignees as string[];
  }

  set assignees(value: string[]) {
    const oldAssignees = this.assignees;
    const newAssignees = value;

    this.debounceChangeAssignees(newAssignees, oldAssignees);
  }

  /**
   * @override
   * @param added
   * @param removed
   */
  changeAssignees(added: string[], removed: string[], all: string[]) {
    if (removed.length > 0) {
      this.$emit("delete:assignees", removed);
    }
    if (added.length > 0) {
      this.$emit("add:assignees", added);
    }

    this.ticket.assignees = all;

    this.$emit("update:assignees", all);
  }

  addXWeeks(weeks: number) {
    const today = new Date();
    const date = this.ticket.due
      ? new Date(this.ticket.due)
      : new Date(today.getFullYear(), today.getMonth(), today.getDay());

    date.setDate(date.getDate() + 7 * weeks);

    this.ticket.due = date.toISOString();
    this.forceDateUpdateKey++;
  }

  async refreshProjects() {
    this.isLoadingProjects = true;
    try {
      ProjectModule.setFilter([]);
      ProjectModule.setHiddenFilter([]);
      await ProjectModule.fetchAll({ partnerId: PartnerModule.partner.id });
    } catch (e) {
      handleError(e);
    }
    this.isLoadingProjects = false;
  }

  goToProject() {
    if (!this.ticket.projectId) {
      return;
    }

    new ProjectGoToHelper(this.$router).go(
      ProjectGoToHelper.locations.projectCustomView(
        PartnerModule.partner.id,
        this.ticket.projectId,
        "0",
        this.ticket.id
      )
    );
  }

  change() {
    this.$emit("change", true);
  }
}
