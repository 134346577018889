






























































































import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import moment from "moment";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class AnalyticsDateRangeSelectorDialog extends DarkModeHighlightMixin {
  SET_DATE = "setDate";

  @Prop()
  dates!: string[];

  @Prop({ default: true })
  showSuggested!: boolean;

  @Prop({ default: false })
  showFuture!: boolean;

  @Prop({ default: false })
  disableTo!: boolean;

  @Prop({ default: false })
  disableFrom!: boolean;

  @Prop()
  min?: string;

  @Prop()
  max?: string;

  @Prop({ default: false })
  hideSelectedItemText?: string;

  /**
   * Which suggested item to be selected by default. As an index from prev or future dates
   * Default is 0(Custom)
   * Used in combination with `showFuture`.
   * if `showFuture` = true; the selected index is from `futureItems` and `previousItems` otherwise
   */
  @Prop({ default: 0 })
  selectedSuggestedDateIndex!: number;

  temporaryDates: string[] = [];
  selectedSuggestedDateIndexTemp: number = this.selectedSuggestedDateIndex;

  showMenu = false;

  previousItems = [
    {
      id: 0,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.custom",
      from: "",
      to: ""
    },
    {
      id: 1,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.today",
      from: this.today,
      to: this.today
    },
    {
      id: 2,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.yesterday",
      from: this.yesterday,
      to: this.yesterday
    },
    {
      id: 3,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.thisWeek",
      from: this.startOfThisWeek,
      to: this.endOfThisWeek
    },
    {
      id: 4,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.lastWeek",
      from: this.startOfLastWeek,
      to: this.endOfLastWeek
    },
    {
      id: 5,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.28",
      from: this.todayMinusDays(28),
      to: this.yesterday
    },
    {
      id: 6,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.30",
      from: this.todayMinusDays(30),
      to: this.yesterday
    },
    {
      id: 7,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.90",
      from: this.todayMinusDays(90),
      to: this.yesterday
    },
    {
      id: 8,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.365",
      from: this.todayMinusDays(365),
      to: this.yesterday
    }
  ];

  futureItems = [
    {
      id: 0,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.custom",
      from: "",
      to: ""
    },
    {
      id: 1,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.nextMonth",
      from: this.today,
      to: this.todayPlusDays(30)
    },
    {
      id: 2,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.nextYear",
      from: this.today,
      to: this.todayPlusDays(365)
    },
    {
      id: 3,
      text: "components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.next2Year",
      from: this.today,
      to: this.todayPlusDays(730)
    }
  ];

  get items() {
    return this.showFuture ? this.futureItems : this.previousItems;
  }

  get temporaryDateFrom(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.min.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
    return simpleDate(tempo);
  }

  get temporaryDateTo() {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.max.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
    return simpleDate(tempo);
  }

  get dateFrom() {
    const dates: number[] = [];
    for (const date of this.dates) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.min.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();

    return simpleDate(tempo);
  }

  get dateTo() {
    const dates: number[] = [];
    for (const date of this.dates) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.max.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
    return simpleDate(tempo);
  }

  get selectedItemText() {
    return this.previousItems[this.selectedSuggestedDateIndexTemp].text;
  }

  get today() {
    return moment()
      .toISOString(true)
      .substring(0, 10);
  }

  get yesterday() {
    return moment()
      .subtract(1, "days")
      .toISOString(true)
      .substring(0, 10);
  }

  get startOfThisWeek() {
    return moment()
      .startOf("isoWeek")
      .toISOString(true)
      .substring(0, 10);
  }

  get endOfThisWeek() {
    return moment()
      .toISOString(true)
      .substring(0, 10);
  }

  get startOfLastWeek() {
    return moment()
      .subtract(1, "weeks")
      .startOf("isoWeek")
      .toISOString(true)
      .substring(0, 10);
  }

  get endOfLastWeek() {
    return moment()
      .subtract(1, "weeks")
      .endOf("isoWeek")
      .toISOString(true)
      .substring(0, 10);
  }

  todayMinusDays(days: number) {
    return moment()
      .subtract(days, "days")
      .toISOString(true)
      .substring(0, 10);
  }

  todayPlusDays(days: number) {
    return moment()
      .add(days, "days")
      .toISOString(true)
      .substring(0, 10);
  }

  datePlusDays(date: string, days: number) {
    return moment(date)
      .add(days, "days")
      .toISOString(true)
      .substring(0, 10);
  }

  setCustomDate() {
    this.selectedSuggestedDateIndexTemp = 8;
  }

  get dateRange() {
    if (this.temporaryDates.length === 0) {
      return this.dates;
    }
    return this.temporaryDates;
  }

  set dateRange(dateRange: string[]) {
    this.temporaryDates = dateRange;
  }

  get disabled() {
    return this.temporaryDates.length === 0;
  }

  showFutureSuggestions() {
    this.showFuture = true;
  }

  showPastSuggestions() {
    this.showFuture = false;
  }

  close() {
    this.showMenu = false;
    this.temporaryDates = [];
  }

  open() {
    this.showMenu = true;
    this.temporaryDates = [];
  }

  setDate(close = true) {
    this.dates.splice(0, 2, ...this.temporaryDates);
    this.$emit(this.SET_DATE, this.temporaryDates);

    if (close) this.close();
  }

  async clickDateRangeSelector(dates: string[]) {
    /**
     * If "to" date cannot be chosen, update the dateRange with
     * the chosen "from" date and a date 30 days from "from" date.
     */
    if (!this.disableTo) {
      return;
    }

    /**
     * Dates come in the format [from, to] from v-datepicker.
     * When the first date is clicked(from), populate the "to" date with 1 month ahead.
     * */
    if (dates.length === 1) {
      dates.push(this.datePlusDays(dates[0], 30));
    }

    this.temporaryDates = dates;
    this.setDate(false);
    this.$emit("clickDateRange", dates[0]);
  }

  usedPredefinedDateRange(item: any) {
    if (!item) {
      return;
    }

    this.selectedSuggestedDateIndexTemp = item.id;

    if (item.from) {
      this.temporaryDates = [item.from, item.to];
    }
  }

  mounted() {
    if (this.selectedSuggestedDateIndexTemp !== 0) {
      this.usedPredefinedDateRange(this.items[this.selectedSuggestedDateIndexTemp]);
      this.setDate(false);
    }
  }
}
