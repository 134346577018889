var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ma-2",
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        title: _vm.$t("components.partner.CustomerContactCard.title")
      },
      scopedSlots: _vm._u(
        [
          {
            key: "actions",
            fn: function fn() {
              return [
                _vm.userId && _vm.navigateToDetails
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: {
                          top: "",
                          "nudge-right": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function fn(_ref) {
                                var on = _ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          icon: ""
                                        },
                                        on: {
                                          click: function click($event) {
                                            return _vm.$router.push({
                                              name: _vm.detailsComponentName,
                                              params: {
                                                userId: _vm.userId
                                              }
                                            })
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3521439091
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "report.ThgChargingStationDetailCard.toDetail"
                              )
                            )
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._t("actions")
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c("v-card-text", [
        _vm.isCompany || _vm.companyName
          ? _c(
              "span",
              [
                _vm.isCompany
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          small: ""
                        }
                      },
                      [_vm._v("mdi-domain")]
                    )
                  : _vm._e(),
                _vm.companyName
                  ? _c("span", [_vm._v(_vm._s(_vm.companyName) + " ")])
                  : _c(
                      "v-icon",
                      {
                        staticClass: "mt-1",
                        attrs: {
                          color: "error",
                          small: ""
                        }
                      },
                      [_vm._v("mdi-minus")]
                    ),
                _c("br")
              ],
              1
            )
          : _vm._e(),
        _vm.isTaxDeductible || _vm.taxnumber
          ? _c(
              "span",
              [
                _vm.isTaxDeductible
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          small: ""
                        }
                      },
                      [_vm._v("mdi-cash-refund")]
                    )
                  : _vm._e(),
                _vm.taxnumber
                  ? _c("span", [_vm._v(_vm._s(_vm.taxnumber) + " ")])
                  : _c(
                      "v-icon",
                      {
                        staticClass: "mt-1",
                        attrs: {
                          color: "error",
                          small: ""
                        }
                      },
                      [_vm._v("mdi-minus")]
                    ),
                _c("br")
              ],
              1
            )
          : _vm._e(),
        _vm.isTaxDeductible || _vm.taxnumber || _vm.isCompany || _vm.companyName
          ? _c("br")
          : _vm._e(),
        _vm.customerName
          ? _c("span", [_vm._v(" " + _vm._s(_vm.customerName) + " "), _c("br")])
          : _vm._e(),
        _vm.address
          ? _c("span", [
              _vm._v(" " + _vm._s(_vm.address.street) + " "),
              _c("br"),
              _vm._v(
                " " +
                  _vm._s(_vm.address.zip) +
                  " " +
                  _vm._s(_vm.address.city) +
                  " "
              ),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.address.state) + " "),
              _c("br"),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "country.".concat(
                        _vm.address.countryCode || _vm.CountryCodeEnum.germany
                      )
                    )
                  ) +
                  " (" +
                  _vm._s(_vm.address.countryCode) +
                  ") "
              ),
              _c("br")
            ])
          : _vm._e(),
        _vm.customerContact
          ? _c(
              "span",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      small: ""
                    }
                  },
                  [_vm._v("mdi-email-outline")]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "mailto:".concat(_vm.customerContact.email)
                    }
                  },
                  [_vm._v(_vm._s(_vm.customerContact.email))]
                ),
                _vm._v(" "),
                _c("br"),
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      small: ""
                    }
                  },
                  [_vm._v("mdi-phone-outline")]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "mailto:".concat(_vm.customerContact.phone)
                    }
                  },
                  [_vm._v(_vm._s(_vm.customerContact.phone))]
                ),
                _vm._v(" "),
                _c("br")
              ],
              1
            )
          : _vm._e(),
        _vm.email
          ? _c(
              "span",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      small: ""
                    }
                  },
                  [_vm._v("mdi-email-outline")]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "mailto:".concat(_vm.email)
                    }
                  },
                  [_vm._v(_vm._s(_vm.email))]
                ),
                _vm._v(" "),
                _c("br")
              ],
              1
            )
          : _vm._e(),
        _vm.phone
          ? _c(
              "span",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      small: ""
                    }
                  },
                  [_vm._v("mdi-phone-outline")]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "tel:".concat(_vm.phone)
                    }
                  },
                  [_vm._v(_vm._s(_vm.phone))]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }