

































import { requiredRule } from "@/lib/rules/requiredRule";
import { deepCopy } from "@/lib/utility/deep-copy";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";

@Component({
  components: {}
})
export default class PartnerCreateFromTemplateDialog extends DarkModeHighlightMixin {
  dialog = false;
  valid = false;

  partnerTemplate: PartnerEntity = undefined as any;

  get partners() {
    return PartnerModule.partners;
  }

  closeDialog() {
    this.dialog = false;
  }

  useTemplate() {
    this.$emit("getTemplate", deepCopy(this.partnerTemplate));
    this.dialog = false;
  }

  /**
   * Rules
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }
}
