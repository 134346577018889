











































































































































































import { WeekDaysEnum } from "@/lib/enum/weekDays.enum";
import { emailRule, extendetPhoneRule } from "@/lib/rules/contactRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  MrfiktivPartnerSettingsDocumentGen,
  MrfiktivPartnerSettingsDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { CreatePartnerSettingsDto } from "@/store/models/mrfiktiv/partner-settings";
import { Component, Prop } from "vue-property-decorator";
import PartnerCreateOrUpdateLandingpageSettigns from "./PartnerCreateOrUpdateLandingpageSettigns.vue";
import PartnerCreateOrUpdateLicense from "./PartnerCreateOrUpdateLicense.vue";
import PartnerCreateOrUpdateReportSettings from "./PartnerCreateOrUpdateReportSettings.vue";

@Component({
  components: {
    PartnerCreateOrUpdateLandingpageSettigns,
    PartnerCreateOrUpdateReportSettings,
    PartnerCreateOrUpdateLicense
  }
})
export default class PartnerSettingsForm extends DarkModeHighlightMixin {
  tab = 0;

  @Prop()
  settings!: MrfiktivPartnerSettingsDocumentGen;

  @Prop({ default: false })
  isLoading!: boolean;

  weekDays = Object.keys(WeekDaysEnum).map(k => WeekDaysEnum[k as any]);

  disabled = true;

  valid = false;

  update: MrfiktivPartnerSettingsDtoGen = new CreatePartnerSettingsDto(this.settings);

  initialize() {
    this.update = new CreatePartnerSettingsDto(this.settings);
  }

  mounted() {
    this.initialize();
  }

  save(): MrfiktivPartnerSettingsDtoGen {
    this.$emit("save", this.update);
    this.disabled = true;

    return this.update;
  }

  abort() {
    this.initialize();
    this.disabled = true;
  }

  get emailRules() {
    const rules = [];

    rules.push(emailRule());

    return rules;
  }

  get phoneRules() {
    const rules = [];

    rules.push(extendetPhoneRule());

    return rules;
  }
}
