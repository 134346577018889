var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticStyle: {
        height: "100%"
      }
    },
    [
      _c(
        "v-form",
        {
          staticStyle: {
            height: "100%"
          },
          model: {
            value: _vm.isValid,
            callback: function callback($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "mb-n10 mt-n2 mt-1"
            },
            [
              !_vm.isCustomSenderSelection
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: "6"
                      }
                    },
                    [
                      _vm.from.length <= 1
                        ? _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "components.template.dialog.preview.sender"
                              ),
                              disabled: "",
                              value: _vm.senderNames[0],
                              "hide-selected": "",
                              flat: "",
                              outlined: "",
                              dense: ""
                            }
                          })
                        : _c("combo-box", {
                            attrs: {
                              dense: "",
                              label: _vm.$t(
                                "components.template.dialog.preview.sender"
                              ),
                              multiple: false,
                              items: _vm.senderNames
                            },
                            on: {
                              changeSelection: _vm.changeSenderSelection,
                              changeIsValid: _vm.changeIsSenderSelectionValid
                            }
                          })
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: "6"
                      }
                    },
                    [_vm._t("senderSelection")],
                    2
                  ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "6"
                  }
                },
                [
                  _c("combo-box", {
                    class: _vm.smallScreenTopMargin,
                    attrs: {
                      dense: "",
                      label: _vm.$t(
                        "components.template.dialog.preview.receiver"
                      ),
                      items: _vm.to,
                      rules: _vm.receiverMailRules
                    },
                    on: {
                      changeSelection: _vm.changeReceiverSelection,
                      changeIsValid: _vm.changeIsReceiverSelectionValid
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          !_vm.loading
            ? _c(
                "div",
                {
                  staticStyle: {
                    height: "100%"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "100%"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticStyle: {
                                  "z-index": "1"
                                },
                                attrs: {
                                  dense: "",
                                  rules: _vm.textRules,
                                  outlined: "",
                                  label: _vm.$t(
                                    "components.template.dialog.preview.subject"
                                  )
                                },
                                model: {
                                  value: _vm.renderedSubject,
                                  callback: function callback($$v) {
                                    _vm.renderedSubject = $$v
                                  },
                                  expression: "renderedSubject"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-n7",
                          style: "height: calc(100% - ".concat(
                            _vm.templateEditorOffset,
                            "px);"
                          )
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: {
                                height: "100%"
                              },
                              attrs: {
                                cols: "12"
                              }
                            },
                            [
                              _c(
                                "template-editor",
                                {
                                  key: _vm.k,
                                  attrs: {
                                    readOnly: false,
                                    partnerId: _vm.partnerId,
                                    isMobile: _vm.isMobile,
                                    label: _vm.$t(
                                      "components.template.detail.body.body"
                                    )
                                  },
                                  model: {
                                    value: _vm.renderedBody,
                                    callback: function callback($$v) {
                                      _vm.renderedBody = $$v
                                    },
                                    expression: "renderedBody"
                                  }
                                },
                                [
                                  _vm.hasTokens
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              class: _vm.isMobile
                                                ? "mr-n1 ml-n1"
                                                : "pr-0 pl-0",
                                              attrs: {
                                                text: "",
                                                small: "",
                                                icon: _vm.isMobile
                                              },
                                              on: {
                                                click: function click($event) {
                                                  _vm.customTokenDialog = true
                                                }
                                              }
                                            },
                                            [
                                              !_vm.isMobile
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass: "mr-1 ml-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.template.dialog.preview.customTokens"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !_vm.isMobile
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: _vm.isTokenWithoutValue
                                                          ? "error"
                                                          : ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-list-status "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "tooltip",
                                                    {
                                                      staticClass:
                                                        "mr-n1 ml-n1",
                                                      attrs: {
                                                        text: _vm.$t(
                                                          "components.template.dialog.preview.missingTokens"
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color: _vm.isTokenWithoutValue
                                                              ? "error"
                                                              : ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-list-status "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          ),
                                          _c("template-editor-action-divider")
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.canReadTemplate
                                    ? _c(
                                        "span",
                                        [
                                          _c("template-selection-dialog", {
                                            attrs: {
                                              partnerId: _vm.partnerId,
                                              context: _vm.extendedContext
                                            },
                                            on: {
                                              select: _vm.setTemplate
                                            }
                                          }),
                                          _c("template-editor-action-divider")
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.problems.length > 0
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mb-n5 mt-n6"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12"
                                  }
                                },
                                _vm._l(_vm.problems, function(problem) {
                                  return _c(
                                    "small",
                                    {
                                      key: problem
                                    },
                                    [_vm._v(_vm._s(problem))]
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _c("v-skeleton-loader", {
                attrs: {
                  type: "list-item-two-line, list-item-two-line"
                }
              })
        ],
        1
      ),
      _vm.customTokenDialog
        ? _c(
            "div",
            [
              _c(
                "confirm-action-dialog",
                {
                  attrs: {
                    title: _vm.$t(
                      "components.template.dialog.preview.customTokens"
                    ),
                    isDialogActive: _vm.customTokenDialog,
                    hideLeft: true
                  },
                  on: {
                    "update:isDialogActive": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.customTokenDialog = $event
                    },
                    "update:is-dialog-active": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.customTokenDialog = $event
                    },
                    rightClick: function rightClick($event) {
                      _vm.customTokenDialog = false
                    }
                  }
                },
                _vm._l(_vm.template.content.tokens, function(token) {
                  return _c(
                    "div",
                    {
                      key: token
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.getValue(token),
                          label: _vm.getLabel(token),
                          outlined: ""
                        },
                        on: {
                          input: function input(v) {
                            return _vm.debounceInput(v, token)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }