









































































import FilterCard from "@/components/filter/FilterCard.vue";
import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { BasePagination, PaginationFilterListElement } from "@/store/modules/base-pagination.store";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../components/utility/Debug.vue";
import { downloadAsXlsx, getTranslationsFromEntity } from "@/lib/download-as-xlsx";
import ContextMenu from "@/components/utility/ContextMenu.vue";
import { FeatureModule } from "@/store/modules/feature.store";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import { IFormableClass } from "@/lib/formable";
import { IPageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    FilterCard,
    FilterCardPagination,
    Debug,
    ContextMenu
  }
})
export default class TheLayoutPortalList<T> extends DarkModeHighlightMixin {
  @Prop()
  title!: string;

  @Prop({ default: () => [] })
  filterList!: IFilterListElement[];

  @Prop()
  loading!: boolean;

  @Prop()
  isLoadingMore!: boolean;

  @Prop()
  getModule!: () => BasePagination<any, any> | PaginatedBaseStore<any, any>;

  @Prop()
  entity?: IFormableClass;

  @Prop({ default: AssetEnum.emptyData })
  emptyAsset!: AssetEnum;

  selected: T | {} = {}; // hack to prevent console error, set by parent

  get isCsvExport() {
    return FeatureModule.isCsvExport;
  }

  get paginationFilterList(): PaginationFilterListElement[] {
    const legacyModule = this.getModule() as BasePagination<any, any>;
    const module = this.getModule() as PaginatedBaseStore<any, any>;
    const isLegacyModule = !!legacyModule?.isLegacy;

    if (isLegacyModule) {
      return legacyModule.filterList;
    } else if (module.filterOptions) {
      return module.filterOptions;
    }

    return [];
  }

  get search(): string | undefined {
    return this.getModule().search;
  }

  set search(search: string | undefined) {
    this.getModule().setSearch(search);
  }

  showMenu = false;

  items = this.paginationList;

  get paginationFilter(): IPageFilterElement[] {
    if ((this.getModule() as BasePagination<any, any>).filter) {
      return (this.getModule() as BasePagination<any, any>).filter;
    } else if ((this.getModule() as PaginatedBaseStore<any, any>).filters) {
      return (this.getModule() as PaginatedBaseStore<any, any>).filters;
    }

    return [];
  }

  set paginationFilter(filter: IPageFilterElement[]) {
    this.getModule().setFilter(filter);
  }

  get paginationList() {
    return this.getModule().paginationList;
  }

  get totalItems() {
    return this.getModule().totalItems;
  }

  get isLoadAll() {
    return (this.getModule() as BasePagination<any, any>).isLoadAll;
  }

  set isLoadAll(value: boolean) {
    (this.getModule() as BasePagination<any, any>).setIsLoadAll(value);
  }

  get progress() {
    return (this.paginationList.length / this.totalItems) * 100;
  }

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, this.emptyAsset);
  }

  showLoadMore(index: number): boolean {
    return index === this.paginationList.length - 1 && this.paginationList.length !== this.totalItems;
  }

  download() {
    let list = this.items;
    if (!list.length) {
      list = this.getModule().paginationList;
    }

    const translations = this.entity?.formables ? getTranslationsFromEntity(this.entity) : undefined;
    downloadAsXlsx(list, translations);
  }

  onFiltered(filteredItems: T[]) {
    this.items = filteredItems;
  }

  loadMore() {
    this.$emit("loadMore");
  }

  refresh() {
    this.$emit("refresh");
  }
}
