import { HandoverStateEnum } from "@/lib/enum/handover-state.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import { Company, ICompany } from "@/models/company.entity";
import { IInspection, Inspection } from "@/models/inspection.entity";
import handoverService from "@/services/mrfiktiv/services/handoverService";
import {
  MrfiktivCreateHandoverDtoGen,
  MrfiktivHandoverViewModelGen,
  MrfiktivUpdateHandoverDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { HandoverDataAccessLayer } from "@/store/modules/access-layers/handover.access-layer";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Attendee, IAttendee } from "./attendee";
import { ITimestamp, Timestamp } from "./timestamp.entity";

@IsFilterable
class HandoverBase implements MrfiktivHandoverViewModelGen, ICreateDto<IHandover> {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.id",
    config: {
      itemCallback: () => HandoverDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-handover"
    }
  })
  id: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.handover.number"
  })
  number: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.vehicleId",
    config: {
      itemCallback: () => VehicleModule.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-vehicle"
    }
  })
  vehicleId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.handover.reports",
    config: {
      itemCallback: () => ReportPaginationModule.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-report"
    }
  })
  reportIds: string[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.handover.title"
  })
  title: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.handover.description"
  })
  description: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.event.state",
    config: {
      items: Object.values(HandoverStateEnum)
    }
  })
  state: HandoverStateEnum;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Company
  })
  owner?: ICompany | undefined;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Company
  })
  keeper?: ICompany | undefined;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Company
  })
  newKeeper?: ICompany | undefined;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Inspection
  })
  inspections: IInspection[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Attendee
  })
  attendees: IAttendee[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  /**
   * Construct handover
   */
  constructor(handover?: Partial<HandoverBase | MrfiktivHandoverViewModelGen>) {
    this.id = handover?.id ?? "";
    this.number = handover?.number ?? -1;

    this.partnerId = handover?.partnerId ?? "";
    this.vehicleId = handover?.vehicleId ?? "";
    this.reportIds = handover?.reportIds ?? [];

    this.title = handover?.title ?? "";
    this.description = handover?.description ?? "";

    this.state = (handover?.state ?? HandoverStateEnum.CLOSED) as HandoverStateEnum;

    this.owner = new Company(handover?.owner);
    this.keeper = new Company(handover?.keeper);
    this.newKeeper = new Company(handover?.newKeeper);

    this.inspections = (handover?.inspections ?? []).map(
      i => new Inspection({ ...i, handoverId: this.id, partnerId: this.partnerId })
    );
    this.attendees = (handover?.attendees ?? []).map(i => new Attendee(i));

    this.timestamp = new Timestamp(handover?.timestamp);
  }

  /**
   * fetch handover
   */
  async fetch(): Promise<this> {
    const res = await handoverService.findOne(this.partnerId, this.id);

    this.map(res);
    HandoverDataAccessLayer.set(this);

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  private map(handover?: MrfiktivHandoverViewModelGen) {
    if (!handover) return;
    this.id = handover.id;
    this.number = handover.number;

    this.partnerId = handover.partnerId;
    this.vehicleId = handover.vehicleId;
    this.reportIds = handover.reportIds;

    this.title = handover.title;
    this.description = handover.description;

    this.state = handover.state as HandoverStateEnum;

    this.owner = new Company(handover.owner);
    this.keeper = new Company(handover.keeper);
    this.newKeeper = new Company(handover.newKeeper);

    this.inspections = (handover?.inspections ?? []).map(
      i => new Inspection({ ...i, handoverId: this.id, partnerId: this.partnerId })
    );
    this.attendees = (handover?.attendees ?? []).map(i => new Attendee(i));

    this.timestamp = new Timestamp(handover.timestamp);
  }

  /**
   * create fetch handover
   */
  async create() {
    const data: MrfiktivCreateHandoverDtoGen = {
      title: this.title,
      description: this.description,
      keeper: this.keeper,
      newKeeper: this.newKeeper,
      owner: this.owner,
      reportIds: this.reportIds,
      attendees: this.attendees,
      vehicleId: this.vehicleId
    };
    const res = await handoverService.create(this.partnerId, data);

    this.map(res);

    HandoverDataAccessLayer.set(this);

    return this;
  }

  /**
   * delete handover
   */
  async delete() {
    const res = await handoverService.remove(this.partnerId, this.id);

    this.map(res);
    HandoverDataAccessLayer.delete(this);
  }

  /**
   * update handover
   * @returns
   */
  async update() {
    const data: MrfiktivUpdateHandoverDtoGen = {
      title: this.title,
      description: this.description,
      keeper: this.keeper,
      newKeeper: this.newKeeper,
      owner: this.owner,
      reportIds: this.reportIds,
      state: this.state,
      attendees: this.attendees
    };
    const res = await handoverService.update(this.partnerId, this.id, data);
    this.map(res);
    HandoverDataAccessLayer.set(this);

    return this;
  }

  /**
   * update handover via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdateHandoverDtoGen) {
    const res = await handoverService.update(this.partnerId, this.id, dto);

    this.map(res);

    HandoverDataAccessLayer.set(this);

    return this;
  }
}

type IHandover = HandoverBase;
const Handover = Filter.createForClass(HandoverBase);

export { Handover, IHandover };
