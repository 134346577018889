var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            flat: "",
            color: _vm.$vuetify.theme.dark ? "grey darken-3" : "white"
          }
        },
        [
          _vm.renderNavigationButton
            ? _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  },
                  on: {
                    click: _vm.goBack
                  }
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _c("v-toolbar-title", [
            _vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")
          ]),
          _c("v-spacer", {
            staticClass: "pr-12"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }