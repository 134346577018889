/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivSharedContentAccessViewmodelGen,
  MrfiktivSharedContentControllerCreatePayloadGen,
  MrfiktivSharedContentControllerGetAllForPartnerParamsGen,
  MrfiktivSharedContentControllerGetAllParamsGen,
  MrfiktivSharedContentControllerUpdatePayloadGen,
  MrfiktivSharedContentViewmodelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class SharedContent<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Shared-Content
   * @name SharedContentControllerGetAll
   * @summary (ADMIN) Get all shared contents
   * @request GET:/shared-content
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivSharedContentViewmodelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  sharedContentControllerGetAll = (query: MrfiktivSharedContentControllerGetAllParamsGen, params: RequestParams = {}) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivSharedContentViewmodelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/shared-content`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Shared-Content
   * @name SharedContentControllerGetAllForPartner
   * @summary Get all shared contents
   * @request GET:/partner/{partnerId}/shared-content
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivSharedContentViewmodelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  sharedContentControllerGetAllForPartner = (
    { partnerId, ...query }: MrfiktivSharedContentControllerGetAllForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivSharedContentViewmodelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/shared-content`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Shared-Content
   * @name SharedContentControllerCreate
   * @summary Create a shared conent
   * @request POST:/partner/{partnerId}/shared-content
   * @secure
   * @response `201` `MrfiktivSharedContentViewmodelGen` Created Shared content
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  sharedContentControllerCreate = (
    partnerId: string,
    data: MrfiktivSharedContentControllerCreatePayloadGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivSharedContentViewmodelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/shared-content`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Shared-Content
   * @name SharedContentControllerUpdate
   * @summary Update a shared content for a partner
   * @request PATCH:/partner/{partnerId}/shared-content/{sharedContentId}
   * @secure
   * @response `200` `MrfiktivSharedContentViewmodelGen` Updated shared content
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  sharedContentControllerUpdate = (
    partnerId: string,
    sharedContentId: string,
    data: MrfiktivSharedContentControllerUpdatePayloadGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivSharedContentViewmodelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/shared-content/${sharedContentId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Shared-Content
   * @name SharedContentControllerFind
   * @summary (ADMIN) Gets an shared content document
   * @request GET:/partner/{partnerId}/shared-content/{sharedContentId}
   * @secure
   * @response `200` `MrfiktivSharedContentViewmodelGen` Found shared content
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  sharedContentControllerFind = (partnerId: string, sharedContentId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSharedContentViewmodelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/shared-content/${sharedContentId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Shared-Content
   * @name SharedContentControllerDelete
   * @summary (ADMIN) Delete an shared content
   * @request DELETE:/partner/{partnerId}/shared-content/{sharedContentId}
   * @secure
   * @response `200` `MrfiktivSharedContentViewmodelGen` the deleted shared content
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  sharedContentControllerDelete = (partnerId: string, sharedContentId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSharedContentViewmodelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/shared-content/${sharedContentId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Shared-Content
   * @name SharedContentAccessControllerAccessForPartner
   * @summary Get all shared documents
   * @request GET:/partner/{partnerId}/shared-content/{sharedContentId}/access/{accessToken}
   * @response `200` `MrfiktivSharedContentAccessViewmodelGen` The documents that were shared
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  sharedContentAccessControllerAccessForPartner = (
    partnerId: string,
    sharedContentId: string,
    accessToken: string,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivSharedContentAccessViewmodelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/shared-content/${sharedContentId}/access/${accessToken}`,
      method: "GET",
      format: "json",
      ...params
    });
}
