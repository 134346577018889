var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _vm.title
        ? _c(
            "v-card-title",
            {
              staticClass: "title"
            },
            [
              _c(
                "h4",
                {
                  staticClass: "text-truncate"
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
              _c("v-spacer"),
              !_vm.subtitle ? _vm._t("headerActions") : _vm._e(),
              _vm.loadAll
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        icon: "",
                        loading: _vm.loadingAll
                      },
                      on: {
                        click: _vm.loadAllWrapper
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-refresh")])],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.subtitle
        ? _c(
            "v-card-subtitle",
            [_vm._v(" " + _vm._s(_vm.subtitle) + " "), _vm._t("headerActions")],
            2
          )
        : _vm._e(),
      _c(
        "v-card",
        {
          class: _vm.margin ? "ma-2" : "",
          attrs: {
            outlined: _vm.outlined
          }
        },
        [
          !_vm.hideTableHeader
            ? _c(
                "v-card-title",
                {
                  staticClass: "title pt-4"
                },
                [
                  !_vm.hideSearch
                    ? _c("v-text-field", {
                        staticClass: "searchField",
                        attrs: {
                          dense: "",
                          "append-icon": "mdi-magnify",
                          label: _vm.i18n["search"],
                          "single-line": "",
                          "hide-details": "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function callback($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    : _vm._t("search"),
                  _c("v-spacer"),
                  _vm.isSelected
                    ? _vm._t(
                        "selectActions",
                        null,
                        {
                          selected: _vm.selected
                        },
                        _vm.selected
                      )
                    : _vm._e(),
                  _vm._t("bodyActions"),
                  _vm.newItems.length > 0 &&
                  !_vm.loadingCreation &&
                  _vm.isDropEnabled
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            outlined: "",
                            color: "error"
                          },
                          on: {
                            click: function click($event) {
                              _vm.newItems = []
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.i18n["drop"]) + " ")]
                      )
                    : _vm._e(),
                  _vm.newItems.length > 0 &&
                  !_vm.loadingCreation &&
                  _vm.isConfirmEnabled
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            outlined: "",
                            color: "success"
                          },
                          on: {
                            click: _vm.confirmNewItemsWrapper
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.i18n["confirm"]) + " ")]
                      )
                    : _vm._e(),
                  _vm.addItem && _vm.confirmNewItems
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: {
                            elevation: "0",
                            color: "info",
                            loading: _vm.loadingCreation
                          },
                          on: {
                            click: _vm.addItem
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.i18n["add"]) + " ")]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          !_vm.hideTableHeader ? _c("v-divider") : _vm._e(),
          _c(
            "v-data-table",
            {
              staticClass: "mt-4",
              class: _vm.isClickableRow ? "row-pointer" : "",
              attrs: {
                loading: _vm.loadingAll || _vm.loading,
                "loading-text": _vm.i18n["loading"],
                search: _vm.search,
                headers: _vm.headers,
                items: _vm.items,
                "hide-default-footer": _vm.hideTableFooter,
                "disable-pagination": _vm.disablePagination,
                page: _vm.pageLocal,
                "items-per-page": _vm.itemsPerPage,
                "single-expand": false,
                "show-select": _vm.showSelect,
                "item-key": _vm.itemKey,
                dense: _vm.dense,
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.sortDesc,
                "footer-props": {
                  itemsPerPageText: _vm.i18n["itemsPerPageText"]
                },
                customFilter: _vm.customFilter,
                "single-select": _vm.singleSelect,
                "custom-group": _vm.customGroup,
                groupBy: _vm.groupBy,
                groupDesc: _vm.groupDesc
              },
              on: {
                "update:page": function updatePage($event) {
                  _vm.pageLocal = $event
                },
                "click:row": function clickRow(e) {
                  return _vm.$emit("click:row", e)
                }
              },
              scopedSlots: _vm._u(
                [
                  _vm.isMobile
                    ? {
                        key: "item",
                        fn: function fn(_ref) {
                          var expand = _ref.expand,
                            index = _ref.index,
                            item = _ref.item,
                            isExpanded = _ref.isExpanded,
                            isMobile = _ref.isMobile,
                            isSelected = _ref.isSelected,
                            select = _ref.select,
                            headers = _ref.headers,
                            on = _ref.on,
                            attrs = _ref.attrs
                          return [
                            _c(
                              "tr",
                              {
                                style: _vm.hasClickRowListener
                                  ? "cursor: pointer;"
                                  : "",
                                on: {
                                  click: function click($event) {
                                    return _vm.$emit("click:row", item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      width: "100vw"
                                    },
                                    attrs: {
                                      colspan: headers.length
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ml-3 my-2",
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center"
                                        }
                                      },
                                      [
                                        _vm.showSelect
                                          ? _c(
                                              "div",
                                              [
                                                isSelected
                                                  ? _c("v-checkbox", {
                                                      attrs: {
                                                        value: true
                                                      },
                                                      on: {
                                                        click: function click(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return select(false)
                                                        }
                                                      }
                                                    })
                                                  : _c("v-checkbox", {
                                                      attrs: {
                                                        value: false
                                                      },
                                                      on: {
                                                        click: function click(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return select(true)
                                                        }
                                                      }
                                                    })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("v-spacer"),
                                        _vm._t(
                                          "controlsActions",
                                          function() {
                                            return [
                                              _vm.isNewItem(item)
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: ""
                                                          },
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.removeItem(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-trash-can"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm.controlElements &&
                                                  _vm.controlElements.length
                                                ? _c("span", [
                                                    _c(
                                                      "span",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              loading:
                                                                _vm
                                                                  .controlElements[0]
                                                                  .loading ||
                                                                false,
                                                              disabled:
                                                                _vm
                                                                  .controlElements[0]
                                                                  .disabled ||
                                                                false
                                                            },
                                                            on: {
                                                              click: function click(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.controlElements[0].action(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .controlElements[0]
                                                                    .icon
                                                                )
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm.controlElements.length >
                                                    1
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                attrs: {
                                                                  "nudge-bottom": -14,
                                                                  "close-on-content-click": false
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function fn(
                                                                        _ref2
                                                                      ) {
                                                                        var on =
                                                                            _ref2.on,
                                                                          attrs =
                                                                            _ref2.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  attrs: {
                                                                                    icon:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-dots-vertical"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list",
                                                                  _vm._l(
                                                                    _vm.controlElements,
                                                                    function(
                                                                      controlElement,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "v-list-item",
                                                                        {
                                                                          key: index,
                                                                          attrs: {
                                                                            disabled:
                                                                              controlElement.disabled ||
                                                                              false
                                                                          },
                                                                          on: {
                                                                            click: function click(
                                                                              $event
                                                                            ) {
                                                                              return controlElement.action(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item-title",
                                                                            {
                                                                              domProps: {
                                                                                textContent: _vm._s(
                                                                                  controlElement.text
                                                                                )
                                                                              }
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "v-list-item-icon",
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    icon:
                                                                                      "",
                                                                                    loading:
                                                                                      controlElement.loading ||
                                                                                      false,
                                                                                    disabled:
                                                                                      controlElement.disabled ||
                                                                                      false
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          controlElement.icon
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ])
                                                : _vm._e()
                                            ]
                                          },
                                          {
                                            item: item
                                          }
                                        )
                                      ],
                                      2
                                    ),
                                    _vm._t(
                                      "item",
                                      function() {
                                        return _vm._l(headers, function(
                                          header,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: "header" + index,
                                              staticClass: "ml-2 mr-1 my-1",
                                              attrs: {
                                                elevation: 0
                                              }
                                            },
                                            [
                                              header.text
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "v-data-table__mobile-row__header ml-2 mr-1"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(header.text) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex"
                                                  }
                                                },
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "span",
                                                    {
                                                      key: header.value
                                                    },
                                                    [
                                                      !_vm.isNewItem(item) &&
                                                      !Boolean(
                                                        header.forceEditField
                                                      )
                                                        ? _c(
                                                            "span",
                                                            [
                                                              header.value
                                                                ? _vm._t(
                                                                    "item.".concat(
                                                                      header.value
                                                                    ),
                                                                    function() {
                                                                      return [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              header.type ===
                                                                                "date"
                                                                                ? _vm.simpleDoubleDigitDate(
                                                                                    _vm.getItemValue(
                                                                                      item,
                                                                                      header.value
                                                                                    )
                                                                                  )
                                                                                : _vm.getItemValue(
                                                                                    item,
                                                                                    header.value
                                                                                  )
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    },
                                                                    {
                                                                      item: item
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            2
                                                          )
                                                        : header.isDisplay
                                                        ? _c("span")
                                                        : _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "mb-n5 mt-2 pt-2 pb-4",
                                                                  attrs: {
                                                                    dense: "",
                                                                    value: _vm.getItemValue(
                                                                      item,
                                                                      header.value
                                                                    ),
                                                                    placeholder:
                                                                      header.placeholder,
                                                                    rules:
                                                                      header.rules,
                                                                    type:
                                                                      header.type,
                                                                    rounded: "",
                                                                    filled: ""
                                                                  },
                                                                  on: {
                                                                    change: function change(
                                                                      v
                                                                    ) {
                                                                      return _vm.setItemValue(
                                                                        item,
                                                                        header.value,
                                                                        v
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        })
                                      },
                                      {
                                        expand: expand,
                                        index: index,
                                        item: item,
                                        isExpanded: isExpanded,
                                        isMobile: isMobile,
                                        isSelected: isSelected,
                                        select: select,
                                        headers: headers,
                                        on: on,
                                        attrs: attrs
                                      }
                                    )
                                  ],
                                  2
                                )
                              ]
                            )
                          ]
                        }
                      }
                    : null,
                  {
                    key: "group.header",
                    fn: function fn(_ref3) {
                      var groupBy = _ref3.groupBy,
                        group = _ref3.group,
                        toggle = _ref3.toggle,
                        isOpen = _ref3.isOpen,
                        items = _ref3.items,
                        headers = _ref3.headers,
                        remove = _ref3.remove,
                        isMobile = _ref3.isMobile
                      return [
                        _c("v-hover", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function fn(_ref4) {
                                  var hover = _ref4.hover
                                  return [
                                    _c(
                                      "td",
                                      {
                                        attrs: {
                                          colspan: headers.length
                                        }
                                      },
                                      [
                                        _c("debug", [
                                          _vm._v(
                                            " headers.length: " +
                                              _vm._s(headers.length) +
                                              " "
                                          )
                                        ]),
                                        _c("debug", [
                                          _vm._v(
                                            " groupBy: " + _vm._s(groupBy) + " "
                                          )
                                        ]),
                                        _c("debug", [
                                          _vm._v(
                                            " group: " + _vm._s(group) + " "
                                          )
                                        ]),
                                        _vm._t(
                                          "group.header",
                                          function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center"
                                                  },
                                                  on: {
                                                    click: toggle
                                                  }
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(" " + _vm._s(group))
                                                  ]),
                                                  isOpen
                                                    ? _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-chevron-down"
                                                        )
                                                      ])
                                                    : _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-chevron-right"
                                                        )
                                                      ]),
                                                  _c("v-spacer"),
                                                  hover
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: ""
                                                          },
                                                          on: {
                                                            click: remove
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-close")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          {
                                            groupBy: groupBy,
                                            group: group,
                                            toggle: toggle,
                                            isOpen: isOpen,
                                            items: items,
                                            headers: headers,
                                            remove: remove,
                                            isMobile: isMobile,
                                            hover: hover
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ]
                    }
                  },
                  _vm._l(_vm.headers, function(header) {
                    return {
                      key: "item.".concat(header.value),
                      fn: function fn(_ref5) {
                        var item = _ref5.item
                        return [
                          _c(
                            "span",
                            {
                              key: header.value,
                              style: _vm.hasClickRowListener
                                ? "cursor: pointer;"
                                : ""
                            },
                            [
                              _c("debug", [
                                _vm._v(
                                  " slotname: item." +
                                    _vm._s(header.value) +
                                    " "
                                )
                              ]),
                              !_vm.isNewItem(item) &&
                              !Boolean(header.forceEditField)
                                ? _c(
                                    "span",
                                    [
                                      header.value
                                        ? _vm._t(
                                            "item.".concat(header.value),
                                            function() {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      header.type === "date"
                                                        ? _vm.simpleDoubleDigitDate(
                                                            _vm.getItemValue(
                                                              item,
                                                              header.value
                                                            )
                                                          )
                                                        : _vm.getItemValue(
                                                            item,
                                                            header.value
                                                          )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            },
                                            {
                                              item: item
                                            }
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : header.isDisplay
                                ? _c("span")
                                : _c(
                                    "span",
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mb-n5 mt-2 pt-2 pb-4",
                                        attrs: {
                                          dense: "",
                                          value: _vm.getItemValue(
                                            item,
                                            header.value
                                          ),
                                          placeholder: header.placeholder,
                                          rules: header.rules,
                                          type: header.type,
                                          rounded: "",
                                          filled: ""
                                        },
                                        on: {
                                          change: function change(v) {
                                            return _vm.setItemValue(
                                              item,
                                              header.value,
                                              v
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  }),
                  {
                    key: "item.controls",
                    fn: function fn(_ref6) {
                      var item = _ref6.item
                      return [
                        _vm._t(
                          "controlsActions",
                          function() {
                            return [
                              _vm.isNewItem(item)
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: ""
                                          },
                                          on: {
                                            click: function click($event) {
                                              $event.stopPropagation()
                                              return _vm.removeItem(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-trash-can")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm.controlElements
                                ? _c("span", [
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              loading:
                                                _vm.controlElements[0]
                                                  .loading || false,
                                              disabled:
                                                _vm.controlElements[0]
                                                  .disabled || false
                                            },
                                            on: {
                                              click: function click($event) {
                                                $event.stopPropagation()
                                                return _vm.controlElements[0].action(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.controlElements[0].icon
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.controlElements.length > 1
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  "nudge-bottom": -14,
                                                  "close-on-content-click": false
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function fn(_ref7) {
                                                        var on = _ref7.on,
                                                          attrs = _ref7.attrs
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-dots-vertical"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  _vm._l(
                                                    _vm.controlElements,
                                                    function(
                                                      controlElement,
                                                      index
                                                    ) {
                                                      return _c(
                                                        "v-list-item",
                                                        {
                                                          key: index,
                                                          attrs: {
                                                            disabled:
                                                              controlElement.disabled ||
                                                              false
                                                          },
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return controlElement.action(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              domProps: {
                                                                textContent: _vm._s(
                                                                  controlElement.text
                                                                )
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "v-list-item-icon",
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    loading:
                                                                      controlElement.loading ||
                                                                      false,
                                                                    disabled:
                                                                      controlElement.disabled ||
                                                                      false
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        controlElement.icon
                                                                      )
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ]
                          },
                          {
                            item: item
                          }
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.selected,
                callback: function callback($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [
              _c(
                "template",
                {
                  slot: "no-data"
                },
                [
                  _vm._t("no-data", function() {
                    return [
                      _c("latest-entries-card-empty", {
                        staticClass: "my-8"
                      })
                    ]
                  })
                ],
                2
              ),
              _c(
                "template",
                {
                  slot: "body.prepend"
                },
                [_vm._t("filter")],
                2
              )
            ],
            2
          ),
          [_vm._t("footer")]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }